import TagResume from "components/candidates/Components/Tag"
import styles from "./preferences.sidebar.candidates.module.scss"

const PreferencesCard = ({ candidate }) => {
  return (
    <article className={styles.preferencesCard}>
      <span className={styles.preferencesCard__title}>Preferences</span>

      <div className={styles.preferencesCard__tags}>
        <TagResume label={candidate?.role} icon="check-circle-light" />
        {candidate?.metro_locations?.map((location, index) => (
          <TagResume label={location?.name} icon="location" key={index} />
        ))}

        <TagResume label={candidate?.search_status} icon="resume" />
      </div>
    </article>
  )
}

export default PreferencesCard
