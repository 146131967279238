import Icon from "components/Icon"
import { Link } from "react-router-dom"
import PrimaryButton from "components/shared/Buttons/Primary"
import Card from "components/shared/Card"
import ContactUsLink from "components/shared/ContactUsLink"
import styles from "./EmptyTable.module.scss"

const EmptyTable = ({
  title,
  description,
  buttonText,
  buttonUrl = "/",
  iconHeader = "briefcase2",
  iconButton = "add",
  showButton = true,
  disabled = false,
  onClick,
  externalContact,
  heightIconHeader = "42",
  widthIconHeader = "42",
}) => {
  return (
    <Card>
      <article className={styles.emptyTable}>
        <div className={styles.emptyTable__icon}>
          <Icon
            name={iconHeader}
            height={heightIconHeader}
            width={widthIconHeader}
          />
        </div>

        <h6>{title}</h6>
        <p>{description}</p>
        {showButton && (
          <div className={styles.emptyTable__action}>
            {externalContact ? (
              <ContactUsLink>
                <PrimaryButton
                  className={styles.emptyTable__action}
                  disabled={disabled}
                  onClick={onClick}
                >
                  <span>Contact Us</span>
                  <Icon name="external-link" height="20" width="20" />
                </PrimaryButton>
              </ContactUsLink>
            ) : (
              <PrimaryButton
                className={styles.emptyTable__action}
                disabled={disabled}
                onClick={onClick}
              >
                <Link to={buttonUrl}>
                  <Icon name={iconButton} height="20" width="20" />
                  {buttonText}
                </Link>
              </PrimaryButton>
            )}
          </div>
        )}
      </article>
    </Card>
  )
}

export default EmptyTable
