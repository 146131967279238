import Table from "./Table"
import EmptyTable from "components/shared/EmptyTable"
const EngagedTable = ({ connections, getCandidateProfile }) => {
  return (
    <article>
      {connections?.length > 0 ? (
        <Table data={connections} getCandidateProfile={getCandidateProfile} />
      ) : (
        <EmptyTable
          title={`You don't have any engaged candidates yet`}
          description="Candidates that you or your team connect with will be listed here.
          Connect with a candidate to get started!"
          buttonText="Source Candidates"
          buttonUrl="/source-candidates"
        />
      )}
    </article>
  )
}

export default EngagedTable
