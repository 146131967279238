import { useState, useEffect } from "react"
import CategorySelect from "components/shared/Filters/CategorySelect"

import styles from "./industries.filter.module.scss"

const IndustriesCandidateFilters = ({
  filters,
  setFilters,
  removeFilter,
  options,
}) => {
  const [industries, setIndustries] = useState([])

  const TECHNOLOGY_INDUSTRIES = [
    "Software",
    "Internet",
    "Information Technology and Services",
  ]

  useEffect(() => {
    const industryOptions = options
      .filter(option => !TECHNOLOGY_INDUSTRIES.includes(option.name))
      ?.map(industry => industry.name)
    setIndustries(industryOptions)
  }, [options])

  return (
    <article>
      <CategorySelect
        className={styles.industriesFilter__listIndustries}
        categories={industries}
        watchedCategories={filters.selectedIndustries}
        addFilter={industry =>
          setFilters({
            ...filters,
            selectedIndustries: [...filters.selectedIndustries, industry],
          })
        }
        removeFilter={removeFilter}
      />

      <span className={styles.industriesFilter__label}>Technology</span>

      <CategorySelect
        className={styles.industriesFilter__technologyIndustries}
        categories={TECHNOLOGY_INDUSTRIES}
        watchedCategories={filters.selectedIndustries}
        addFilter={industry =>
          setFilters({
            ...filters,
            selectedIndustries: [...filters.selectedIndustries, industry],
          })
        }
        removeFilter={removeFilter}
      />
    </article>
  )
}

export default IndustriesCandidateFilters
