import mixpanel from "mixpanel-browser"
import { UAParser } from "ua-parser-js"
import { FILTERS_TO_LABELS } from "constants/filters"

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN)

export const pageCall = (name, category = "Employer", data) => {
  const additionalFields = data ? { ...data } : {}
  const agentData = new UAParser(navigator.userAgent)
  const { pathname, search } = window.location

  additionalFields.device = agentData.getDevice()
  additionalFields.name = name
  additionalFields.path = pathname
  additionalFields.search = search
  additionalFields.category = category

  mixpanel.track(`Viewed ${name}`, additionalFields)
}

export const trackAction = (action, data) => {
  mixpanel.track(action, data)
}

export const identifyUser = employer => {
  mixpanel.identify(employer.uuid)
  mixpanel.people.set({
    $email: employer.email,
    customer_name: employer.company.name,
    created_at: employer.created_at,
    access_tier: employer.customer.access_type,
  })
}

export const filterApplied = (
  initialState,
  prevState,
  newState,
  location,
  resultCount
) => {
  const changes = {}

  for (const key in newState) {
    const initialValue = initialState[key]
    const prevValue = prevState[key]
    const newValue = newState[key]

    if (newValue !== prevValue) {
      if (Array.isArray(newValue)) {
        if (newValue.length > prevValue.length) {
          const addedValue = newValue.filter(
            value => !prevValue.includes(value)
          )[0]
          changes.added = {
            [key]: addedValue?.name || addedValue,
          }
        } else if (newValue.length < prevValue.length) {
          const removedValue = prevValue.filter(
            value => !newValue.includes(value)
          )[0]
          changes.removed = {
            [key]: removedValue?.name || removedValue,
          }
        }
      } else {
        if (
          newValue !== initialValue ||
          (newValue?.max &&
            (newValue?.min !== initialValue?.min ||
              newValue?.max !== initialValue?.max))
        ) {
          changes.added = {
            [key]: newValue,
          }
        } else {
          changes.removed = {
            [key]: prevValue,
          }
        }
      }
    }
  }

  const added = Object.keys(changes).includes("added")
  const changesKey = added ? "added" : "removed"
  const propertyChanged = Object.keys(changes[changesKey])[0]
  const actionTracked = added ? "Filter Applied" : "Filter Removed"

  const getMinMax = (key, value, limit) => {
    const experienceKeys = ["yearsClosingExperience", "yearsExperience"]
    const salesCycleMap = {
      0: "1 week",
      1: "2 weeks",
      2: "3 weeks",
      3: "4 weeks",
      4: "5 weeks",
      5: "6 weeks",
      6: "2 months",
      7: "3 months",
      8: "4 months",
      9: "5 months",
      10: "6 months",
      11: "7 months",
      12: "8 months",
      13: "9 months",
      14: "10 months",
      15: "11 months",
      16: "12 months",
    }

    if (experienceKeys.includes(key) && value === 0 && limit === "min")
      return "< 1"
    if (experienceKeys.includes(key) && value === 11 && limit === "max")
      return "10+"
    if (key === "salesCycleLength") return salesCycleMap[value]

    return value
  }

  const filterValue = changes[changesKey][propertyChanged]?.max
    ? `Min: ${getMinMax(
        propertyChanged,
        changes[changesKey][propertyChanged].min,
        "min"
      )}, Max: ${getMinMax(
        propertyChanged,
        changes[changesKey][propertyChanged].max,
        "max"
      )}`
    : changes[changesKey][propertyChanged]

  const trackObject = {
    filter_location: location,
    filter_category: FILTERS_TO_LABELS[propertyChanged],
    filter_option: filterValue,
    filter_result_count: resultCount,
  }

  trackAction(actionTracked, trackObject)
}
