import React from "react"
import classNames from "classnames"

import Button from "react-bootstrap/Button"

import styles from "./SecondaryLight.module.scss"

const SecondaryLightButton = React.forwardRef((props, ref) => {
  const {
    children,
    onClick,
    disabled,
    size,
    type,
    className,
    id,
    variant = "outline-dark",
    ...rest
  } = props
  return (
    <Button
      className={classNames({
        [`${styles.secondaryLight}`]: variant === "outline-dark",
        [`${styles.small}`]: size === "small",
        [`${styles.medium}`]: size === "medium",
        [`${styles.large}`]: size === "large",
        [`${styles.extraLarge}`]: size === "xl",
        [styles.link]: variant === "link",
        [`${className}`]: className,
      })}
      disabled={disabled}
      onClick={onClick}
      variant={variant}
      ref={ref}
      type={type}
      id={id}
      {...rest}
    >
      {children}
    </Button>
  )
})

SecondaryLightButton.displayName = "SecondaryLightButton"

export default SecondaryLightButton
