import classNames from "classnames"
import styles from "./Tabs.module.scss"

const CandidatesTabs = ({ changeTab, counts, activeTab }) => (
  <div className={styles.wrapper}>
    <button
      className={classNames(styles.button, {
        [`${styles.button__active}`]: activeTab === "All",
      })}
      onClick={() => changeTab("All")}
    >
      All Candidates <div className={styles.badge}>{counts.all || 0}</div>
    </button>
    {/*    <button
      className={classNames(styles.button, {
        [`${styles.button__active}`]: activeTab === "Saved",
      })}
      onClick={() => changeTab("Saved")}
    >
      Saved <div className={styles.badge}>{counts.saved || 0}</div>
    </button> */}
  </div>
)

export default CandidatesTabs
