import { useState, useEffect } from "react"
import classNames from "classnames"
import { useLocation } from "react-router-dom"
import BetaTag from "components/shared/BetaTag"
import Accordion from "react-bootstrap/Accordion"
import Icon from "components/Icon"
import { Link } from "react-router-dom"

import styles from "./Accordion.module.scss"

const AccordionMenuItem = ({ item, freeTierAccess }) => {
  const location = useLocation()

  const { title, icon, subItems, beta } = item

  const currentPath = subItems
    .map(item => item.path)
    .includes(location.pathname)

  const [isActive, setIsActive] = useState(currentPath)
  const [activeSubItem, setActiveSubItem] = useState(currentPath)

  useEffect(() => {
    setIsActive(currentPath)
    setActiveSubItem(currentPath)
  }, [currentPath, location.pathname])

  return (
    <Accordion
      key={title}
      className={styles.accordion}
      as="li"
      defaultActiveKey={
        subItems.map(item => item.path).includes(location.pathname) ? "0" : null
      }
    >
      <Accordion.Item eventKey={"0"} as="ul">
        <Accordion.Header onClick={() => setIsActive(!isActive)}>
          <div
            className={classNames({
              [`${styles.accordion__item__active}`]: isActive,
              [`${styles.accordion__item}`]: true,
              [`${styles.accordion__item__activeSubItem}`]: activeSubItem,
            })}
          >
            <Icon name={icon} height={22} width={22} />
            {title} {beta && <BetaTag />}
            <Icon
              name="chevron"
              height={14}
              width={14}
              className={styles.accordion__chevron}
            />
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <div className={styles.accordion__body}>
            {subItems.length > 0 &&
              subItems.map(subItem => {
                if (subItem.restricted && freeTierAccess) return null
                return (
                  <li key={`sub${subItems.indexOf(subItem)}`}>
                    <Link
                      to={subItem.path}
                      className={classNames({
                        [`${styles.accordion__subItem}`]: true,
                        [`${styles.accordion__subItem__active}`]:
                          location.pathname === subItem.path,
                      })}
                    >
                      {subItem.title}
                    </Link>
                  </li>
                )
              })}
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

export default AccordionMenuItem
