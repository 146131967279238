import cn from "classnames"
import Icon from "components/Icon"
import styles from "./CategorySelect.module.scss"

const CategorySelect = ({
  categories,
  watchedCategories,
  addFilter,
  removeFilter,
  className,
}) => {
  const handleSelect = async category => {
    if (watchedCategories.includes && watchedCategories.includes(category)) {
      removeFilter(category)
    } else {
      addFilter(category)
    }
  }

  return (
    <div className={cn(styles.categories, className)}>
      <ul className={styles.categories__list}>
        {categories?.length > 0 &&
          categories.map(category => (
            <li
              className={styles.categories__selection}
              onClick={() => {
                handleSelect(category)
              }}
              key={categories.indexOf(category)}
            >
              {watchedCategories?.length &&
              watchedCategories.includes(category) ? (
                <Icon name="checkbox" height={18} width={18} />
              ) : (
                <div className={styles.categories__checkbox} />
              )}
              {category?.name || category}
            </li>
          ))}
      </ul>
    </div>
  )
}

export default CategorySelect
