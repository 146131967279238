import React from "react"
import styles from "./options.connect.candidates.module.scss"

import { components } from "react-select"
const { Option, SingleValue } = components

const TYPES = {
  "Fully remote": "Fully Remote in US",
}

export const CustomValue = ({ children, ...props }) => {
  const [selectedOption] = props.getValue()
  return (
    <SingleValue {...props}>
      <article className={styles.control}>
        <div className={styles.control__label}>{children}</div>

        <div className={styles.control__location}>
          {selectedOption?.locations?.length === 0 && (
            <span>
              {TYPES[selectedOption?.locationType] ||
                selectedOption?.locationType}
            </span>
          )}

          {selectedOption?.locations?.map(location => (
            <span key={location?.id}>{location?.name}</span>
          ))}
        </div>
      </article>
    </SingleValue>
  )
}

export const CustomOption = props => (
  <Option {...props}>
    <article>
      <div>{props.data.label}</div>
      <div className={styles.options__location}>
        {props?.data?.locations?.length === 0 && (
          <span>
            {TYPES[props?.data?.locationType] || props?.data?.locationType}
          </span>
        )}

        {props.data.locations?.map(location => (
          <span key={`${location?.id}-option`}>{location?.name}</span>
        ))}
      </div>
    </article>
  </Option>
)
