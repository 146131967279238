import cn from "classnames"
import styles from "./descriptionCard.candidate.module.scss"

const Description = ({ strong, candidate }) => {
  return (
    <article
      className={cn(styles.description, {
        [styles.description__strong]: strong,
      })}
    >
      <p
        dangerouslySetInnerHTML={{
          __html: candidate?.summary || "",
        }}
      />
    </article>
  )
}

export default Description
