//import Icon from "components/Icon"
import LabelsCard from "components/candidates/CardApplicant/Components/Labels"
import TagResume from "components/candidates/Components/Tag"
import LogosList from "components/candidates/Components/LogosList"
import styles from "./headerCard.candidate.module.scss"

const HeaderCard = ({ candidate }) => {
  return (
    <article className={styles.headerCard}>
      {/*  <Icon
        name={candidate?.save ? "bookmark-fill" : "bookmark"}
        height="19"
        width="20"
        className={styles.headerCard__mark}
      /> */}

      <div className={styles.headerCard__container}>
        <div>
          <div className={styles.headerCard__titleContainer}>
            <h4 className={styles.headerCard__title}>
              {candidate?.first_name} {candidate?.last_name}
            </h4>
            <LabelsCard candidate={candidate} />
          </div>

          <div className={styles.headerCard__labels}>
            {candidate?.metro_locations?.length === 0 && (
              <TagResume label="Location not specified" icon="location" />
            )}

            {candidate?.metro_locations?.slice(0, 3)?.map((location, index) => (
              <TagResume
                label={location?.name}
                icon="location"
                key={`${index}-location`}
                caption={
                  index === 2 &&
                  candidate?.metro_locations?.length > 3 &&
                  `+ ${candidate?.metro_locations?.length - 3} others`
                }
              />
            ))}
          </div>
        </div>

        <LogosList companies={candidate?.companies} />
      </div>
    </article>
  )
}

export default HeaderCard
