import { TYPES } from "constants/candidates"
import LabelCandidate from "components/candidates/Components/Label"
import ConnectionLabelCandidate from "components/candidates/Components/ContactedLabel"

import styles from "./labels.sidebar.candidates.module.scss"

const LabelsCard = ({ candidate }) => {
  return (
    <article className={styles.labelsCard}>
      {TYPES[candidate?.search_status] && (
        <LabelCandidate
          label={candidate?.search_status}
          type={TYPES[candidate?.search_status]}
        />
      )}

      {candidate?.engaged && (
        <>
          <LabelCandidate label="Engaged" type="engaged" />
          <ConnectionLabelCandidate connection={candidate?.last_connection} />
        </>
      )}
    </article>
  )
}

export default LabelsCard
