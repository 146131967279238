import Icon from "components/Icon"
import styles from "./tag.candidateProfile.module.scss"

const TagResume = ({
  icon,
  label,
  widthIcon = "16",
  heightIcon = "16",
  caption,
}) => {
  return (
    <article className={styles.tag}>
      <Icon
        className={styles.tag__icon}
        name={icon}
        height={widthIcon}
        width={heightIcon}
      />

      <span className={styles.tag__label}>{label}</span>
      {caption && <span className={styles.tag__label__caption}>{caption}</span>}
    </article>
  )
}

export default TagResume
