import { Spinner } from "react-bootstrap"
import styles from "./loader.candidate.module.scss"

const LoaderCandidate = () => {
  return (
    <article className={styles.loader}>
      <Spinner animation="border" />
    </article>
  )
}

export default LoaderCandidate
