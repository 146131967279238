import { useMemo, useState } from "react"
import cn from "classnames"
import debounce from "lodash/debounce"

import Icon from "components/Icon"
import Form from "react-bootstrap/Form"

import styles from "../CompanySearch/CompanySearch.module.scss"

const HeadquartersSearch = ({
  headquartersArray,
  handleSearch,
  handleSelect,
  selectedHeadquarters,
}) => {
  const [value, setValue] = useState("")

  const debounceHandleSearch = useMemo(
    () => debounce(handleSearch, 200),
    [handleSearch]
  )

  const changeValue = value => {
    setValue(value)
    debounceHandleSearch(value)
  }

  return (
    <div
      className={cn(`${styles.search}`, {
        [`${styles.search__hasResults}`]: headquartersArray?.length > 11,
      })}
    >
      <div className={styles.inputWrapper}>
        <Icon
          name="search"
          height="20"
          width="20"
          className={styles.iconLeft}
        />
        <Form.Control
          className={styles.input}
          type="search"
          placeholder="Search locations"
          name="search"
          onChange={e => changeValue(e.target.value)}
        />
      </div>

      {headquartersArray.length ? (
        <div className={styles.results}>
          {headquartersArray.map(headquarters => (
            <div
              className={styles.results__selection}
              onClick={() => {
                handleSelect(headquarters)
              }}
              key={headquartersArray.indexOf(headquarters)}
            >
              {selectedHeadquarters.includes(headquarters) ? (
                <Icon name="checkbox" height={18} width={18} />
              ) : (
                <div className={styles.results__checkbox} />
              )}
              {selectedHeadquarters === headquarters ? (
                <strong className={styles.results__selected}>
                  {headquarters.name}
                </strong>
              ) : (
                headquarters.name
              )}
            </div>
          ))}
        </div>
      ) : (
        <div className={styles.results__selection}>
          {value?.length > 0 && "No results were found."}
        </div>
      )}
    </div>
  )
}

export default HeadquartersSearch
