import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import apiCall from "utils/api"
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom"
import { getCurrentEmployer } from "api/auth"
import { authTokenPresent } from "utils/auth"
import { useSelector } from "react-redux"
import { doNotUpdate, setAppVersion } from "reducers/auth"

import AuthRoute from "routes/auth"
import SignInRoute from "routes/signIn"
import ForgotPasswordRoute from "routes/forgotPassword"
import ResetPasswordRoute from "routes/resetPassword"
import SignUpRoute from "routes/signup"

import AcceptInviteRoute from "routes/acceptInvite"
import ProtectedRoute from "routes/protected"
import DashboardRoute from "routes/dashboard"
import SalesOrgHealthRoute from "routes/salesOrgHealth"
import BattlecardsRoute from "routes/battlecards"
import BattlecardsNewRoute from "routes/battlecards/new"
import TalentCompetitorsRoute from "routes/talentCompetitor"
import JobListingsRoute from "routes/jobs/Listings"
import JobAdsRoute from "routes/jobs/Ads"
import ApplicantsRoute from "routes/applicants/Applicants"
import QuestionsRoute from "routes/questions"
import SettingsRoute from "routes/settings/Settings"
import ReviewsRoute from "routes/reviews/Reviews"
import JobFormRoute from "routes/jobs/Form"
import CompleteRegistrationRoute from "routes/completeRegistration"
import NewVersionNotification from "components/NewVersionNotification"
import TalentDataRoute from "routes/talentData"
import TalentProfilesRoute from "routes/talentProfiles"
import JobListingsDetailsRoute from "routes/jobs/jobListingDetails"
import ProfileRoute from "routes/profile"
import SourceCandidatesRoute from "routes/sourceCandidates"
import EngagedSourceCandidatesRoute from "routes/sourceCandidates/engaged"

import "./App.scss"

const App = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)

  const { fingerprint } = useSelector(state => state.auth)

  const handleUpdateModalClose = (update = true) => {
    if (update) {
      window.location.reload()
    } else {
      dispatch(doNotUpdate())
    }
  }

  useEffect(() => {
    if (authTokenPresent()) {
      dispatch(getCurrentEmployer())
        .then(() => {
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
  }, [dispatch])

  useEffect(() => {
    const updateVersionCheck = setInterval(() => {
      if (fingerprint.updated) {
        const url = `https://${window.location.host}`
        apiCall("get", url).then(response => {
          const regex = /js\/main\.(.*?)\.js/g
          const fingerprint = regex.exec(response.data)[1]
          if (fingerprint) {
            dispatch(setAppVersion(fingerprint))
          }
        })
      }
    }, 60000)
    return () => clearInterval(updateVersionCheck)
  }, [dispatch, fingerprint])

  return (
    <>
      {!loading && (
        <Router>
          <Routes>
            <Route element={<AuthRoute />}>
              <Route path="/sign_in" element={<SignInRoute />} />
              <Route path="/sign_up" element={<SignUpRoute />} />
              <Route
                path="/complete_registration"
                element={<CompleteRegistrationRoute />}
              />
              <Route
                path="/forgot_password"
                element={<ForgotPasswordRoute />}
              />
              <Route path="/reset_password" element={<ResetPasswordRoute />} />
              <Route path="/accept_invite" element={<AcceptInviteRoute />} />
            </Route>
            <Route element={<ProtectedRoute />}>
              <Route path="/dashboard" element={<DashboardRoute />} />
              <Route path="/health" element={<SalesOrgHealthRoute />} />
              <Route path="/battlecards" element={<BattlecardsRoute />} />
              <Route
                path="/battlecards/new"
                element={<BattlecardsNewRoute />}
              />
              <Route
                path="/battlecards/:id/edit"
                element={<BattlecardsNewRoute />}
              />
              <Route path="/talent-data" element={<TalentDataRoute />} />
              <Route path="/competitors" element={<TalentCompetitorsRoute />} />
              <Route
                path="/talent-profiles"
                element={<TalentProfilesRoute />}
              />
              <Route
                path="/talent-profiles/:id"
                element={<TalentDataRoute />}
              />
              <Route path="/jobs" element={<JobListingsRoute />} />
              <Route path="/jobs/:id" element={<JobListingsDetailsRoute />} />
              <Route path="/jobs/:id/edit" element={<JobFormRoute />} />
              <Route path="/jobs/new" element={<JobFormRoute />} />
              <Route path="/ads" element={<JobAdsRoute />} />
              <Route path="/applicants" element={<ApplicantsRoute />} />
              <Route path="/reviews" element={<ReviewsRoute />} />
              <Route path="/questions" element={<QuestionsRoute />} />
              <Route path="/settings" element={<SettingsRoute />} />
              <Route path="/profile" element={<ProfileRoute />} />
              <Route
                path="/source-candidates"
                element={<SourceCandidatesRoute />}
              />
              <Route
                path="/source-candidates/profiles/:uuid"
                element={<SourceCandidatesRoute />}
              />
              <Route
                path="/source-candidates/engaged"
                element={<EngagedSourceCandidatesRoute />}
              />
            </Route>
            <Route path="*" element={<Navigate to="/sign_in" />} />
          </Routes>
        </Router>
      )}
      <NewVersionNotification
        show={!fingerprint?.updated && !fingerprint.keepCurrentVersion}
        closeModal={handleUpdateModalClose}
      />
    </>
  )
}

export default App
