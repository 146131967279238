import { useMemo } from "react"
import debounce from "lodash/debounce"

import Icon from "components/Icon"
import Form from "react-bootstrap/Form"

import styles from "./CompanySearch.module.scss"

const SearchFilter = ({
  handleSearch,
  placeholder = "Search",
  type = "search",
}) => {
  const debounceHandleSearch = useMemo(
    () => debounce(handleSearch, 350),
    [handleSearch]
  )

  return (
    <div className={styles.search}>
      <div className={styles.inputWrapper}>
        <Icon
          name="search"
          height="20"
          width="20"
          className={styles.iconLeft}
        />
        <Form.Control
          className={styles.input}
          type={type}
          placeholder={placeholder}
          name="search"
          onChange={e => debounceHandleSearch(e.target.value)}
        />
      </div>
    </div>
  )
}

export default SearchFilter
