import styles from "./description.cardExperience.module.scss"

const DescriptionCardExperience = ({ description }) => {
  return (
    <div className={styles.descriptionCardExperience}>
      <p>{description}</p>
    </div>
  )
}

export default DescriptionCardExperience
