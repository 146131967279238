import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import cn from "classnames"
import { trackAction } from "utils/mixpanel"
import { useSelector } from "react-redux"

import Spinner from "react-bootstrap/Spinner"
import Icon from "components/Icon"
import SelectInput from "components/shared/SelectInput"
import PrimaryButton from "components/shared/Buttons/Primary"
import { CustomOption, CustomValue } from "./Options"
import styles from "./connect.sidebar.candidates.module.scss"

const CandidateConnect = ({
  uuid,
  name,
  fullName,
  show,
  hide,
  jobs,
  connectCandidate,
  loading,
  errors,
  setErrors,
  schedulingLink,
  openSchedulingLinkModal,
}) => {
  const [form, setForm] = useState({})
  const [options, setOptions] = useState([])

  const { currentEmployer } = useSelector(state => state.auth)

  const changeValue = value => {
    setErrors({})
    setForm(value)
  }

  const handleConnect = () => {
    if (!form?.value) return setErrors({ job: "Please select a job" })

    const payload = {
      connection: {
        job_id: form?.value,
      },
    }

    trackAction("Call to Action Clicked", {
      location: "Candidate Connection",
      click_text: "Connect with Candidate",
      click_url: null,
      modal_name: null,
    })

    connectCandidate(uuid, payload, form?.label, fullName)
  }

  const getTitle = (jobs, schedulingLink, name) => {
    if (!schedulingLink) return "You must add a scheduling link to connect."
    if (jobs?.length === 0) return "Looks like you don’t have any jobs posted."
    if (jobs?.length >= 1) return `Great let’s connect you with ${name}!`
  }

  const getDescription = (jobs, schedulingLink) => {
    if (!schedulingLink)
      return "In order to connect with a candidate, you must have a scheduling link."
    if (jobs?.length === 0)
      return "In order to connect with a candidate, you must have a job posted."
    if (jobs?.length >= 1)
      return `Choose an active job you’d like to connect with them about and we’ll let them know you’re interested.`
  }

  useEffect(() => {
    const optionsMap = jobs?.map(job => {
      return {
        label: job.title,
        value: job.id,
        locations: job.metro_locations,
        locationType: job.location_type,
      }
    })

    setOptions(optionsMap)
  }, [jobs])

  useEffect(() => {
    if (form?.label) {
      trackAction("Job Listing Clicked", {
        job_posting: form?.label,
        company_name: currentEmployer?.company?.name,
      })
    }
  }, [form])

  return (
    <article
      className={cn(styles.candidateConnect, {
        [styles.candidateConnect__show]: show,
      })}
    >
      <h3 className={styles.candidateConnect__title}>
        {getTitle(jobs, schedulingLink, name)}
      </h3>
      <p className={styles.candidateConnect__description}>
        {getDescription(jobs, schedulingLink)}
      </p>

      {jobs?.length >= 1 && schedulingLink && (
        <div className={styles.candidateConnect__select}>
          <SelectInput
            onChange={changeValue}
            value={form.role}
            className={styles.perPageDropdown}
            name="role"
            options={options}
            error={errors?.job || errors?.profile_id}
            errorMessage={
              errors?.profile_id
                ? `${name} has already been contacted for this role, try a different candidate.`
                : errors?.job
            }
            selectStyles={{
              control: base => ({
                ...base,
                height: "50px",
                boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.03)",
                border: "1px solid #E1E4E8",
                borderRadius: "0.375rem",
                fontWeight: "700",
              }),
            }}
            components={{
              SingleValue: CustomValue,
              IndicatorSeparator: () => null,
              Option: CustomOption,
            }}
          />
        </div>
      )}
      <div className={styles.candidateConnect__actions}>
        {jobs?.length >= 1 && schedulingLink && (
          <PrimaryButton onClick={handleConnect} disabled={loading}>
            {loading ? (
              <Spinner size="sm" animation="border" />
            ) : (
              "Connect with Candidate"
            )}
          </PrimaryButton>
        )}

        {jobs?.length === 0 && schedulingLink && (
          <Link to="/jobs">
            <PrimaryButton>Post a Job</PrimaryButton>
          </Link>
        )}

        {!schedulingLink && (
          <PrimaryButton onClick={openSchedulingLinkModal}>
            Add link
          </PrimaryButton>
        )}

        <span
          onClick={hide}
          className={styles.candidateConnect__actions__cancel}
        >
          <Icon name="close" height="16" width="16" /> Cancel
        </span>
      </div>
    </article>
  )
}

export default CandidateConnect
