import { trackAction } from "utils/mixpanel"
import EmptyTable from "components/shared/EmptyTable"

const Empty = ({ noApplicants, section }) => {
  const TAB_CONTENT = {
    New: {
      headline: "No new applicants yet",
      body: "Candidates that have recently applied will appear here. Contact us to boost your visibility to potential applicants via job ads on your talent competitor’s profiles!",
      buttons: true,
    },
    Engagement: {
      headline: "Your engagement funnel is empty",
      body: "Candidates that have entered your recruiting funnel will be displayed here. Engage with new applicants to move them into this phase.",
      buttons: false,
    },
    "Hire successful": {
      headline: "No hires yet",
      body: "Candidates that have been successfully hired will be showcased here. Contact us to find your next hire by securing job ads on your talent competitor’s profiles!",
      buttons: true,
    },
    Declined: {
      headline: "No declined candidates",
      body: "Candidates that have declined will be archived here",
      buttons: false,
    },

    Empty: {
      headline: "No applicants matched your search",
      body: "No applicants matched your search",
    },
  }

  return (
    <EmptyTable
      iconHeader="customer"
      title={TAB_CONTENT[noApplicants ? section : "Empty"].headline}
      description={TAB_CONTENT[noApplicants ? section : "Empty"].body}
      externalContact
      onClick={trackAction("Call to Action Clicked", {
        location: "Empty Applicants Table",
        click_text: "Contact Us",
        click_url: "support@repvue.com",
        modal_name: null,
      })}
      widthIconHeader="50"
      heightIconHeader="50"
    />
  )
}

export default Empty
