import Card from "components/shared/Card"
import HeaderCard from "./Components/HeaderCard"
import Description from "components/candidates/Components/DescriptionCard"
import styles from "./cardApplicant.candidate.module.scss"

const CardApplicant = ({ id, candidate, handleShow }) => {
  return (
    <Card
      className={styles.cardApplicant}
      onClick={() => handleShow(id, candidate?.uuid)}
    >
      <article>
        <HeaderCard candidate={candidate} />
        <Description candidate={candidate} />
      </article>
    </Card>
  )
}

export default CardApplicant
