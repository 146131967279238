/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from "react"
import { Offcanvas } from "react-bootstrap"
import { searchCompanies, getFormOptions } from "api/companies"
import { getMetroLocationsSansCities } from "api/metro_locations"
import AccordionFilter from "components/shared/Filters/AccordionFilter"
import Icon from "components/Icon"
import useFilters from "components/talentData/Hooks/useFilters"

import "./Filters.scss"

const TalentDataFilters = ({
  show,
  handleClose,
  handleFilter,
  currentSort,
  filters,
  setFilters,
  view,
  resetFilters,
}) => {
  const refSettings = useRef(null)
  const [companies, setCompanies] = useState([])
  const [headquarters, setHeadquarters] = useState([])
  const [formOptions, setFormOptions] = useState({})

  const { FILTERS } = useFilters(
    filters,
    setFilters,
    companies,
    setCompanies,
    headquarters,
    setHeadquarters,
    formOptions,
    setFormOptions,
    handleFilter,
    currentSort
  )

  const setDefaults = () => {
    searchCompanies()
      .then(response => {
        setCompanies(response.companies)
      })
      .catch(error => console.error(error))
    getMetroLocationsSansCities()
      .then(response => setHeadquarters(response.rows))
      .catch(error => console.error(error))
    getFormOptions()
      .then(response => {
        setFormOptions(response)
      })
      .catch(error => console.error(error))
  }

  const onHide = () => {
    handleClose()
    setDefaults()
  }

  useEffect(() => {
    setDefaults()
  }, [])

  return (
    <Offcanvas
      className={`filters-${view}`}
      show={show}
      onHide={onHide}
      placement="end"
      scroll={true}
    >
      <div id="setting-conf" ref={refSettings}>
        <Offcanvas.Header className="px-3 pt-3">
          <Offcanvas.Title className="d-flex align-items-center justify-content-between w-100">
            <div className="filter-action-wrapper">
              <span className="offcanvas-title">Filters</span>
              <div
                className="filter-reset"
                onClick={() => resetFilters(null, setFilters)}
              >
                <Icon name="reset" height={15} width={15} />
                <span>Reset</span>
              </div>
            </div>

            <div className="filter-action-wrapper">
              <Icon
                name="close"
                height={20}
                width={20}
                onClick={handleClose}
                className="offcanvas-close"
              />
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {FILTERS.map(filter => {
            return (
              <AccordionFilter
                type={filter.type}
                title={filter.title}
                eventKey={FILTERS.indexOf(filter)}
                body={filter.body}
                key={FILTERS.indexOf(filter)}
                watchedValues={filter.watchedValues}
                removeFilter={filter.removeFilter}
              />
            )
          })}
        </Offcanvas.Body>
      </div>
    </Offcanvas>
  )
}

export default TalentDataFilters
