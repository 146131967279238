import { useEffect, useState, useCallback } from "react"
import { Button, Spinner } from "react-bootstrap"
import { getConnections } from "api/candidate/profiles"
import { pageCall } from "utils/mixpanel"
import { Link } from "react-router-dom"
import { getCandidateProfileByUuid } from "api/candidate/profiles"
import toast from "react-hot-toast"
import ErrorToast from "components/shared/Notifications/ErrorToast"
import Header from "components/Header"
import Content from "components/Content"
import Icon from "components/Icon"
import EngagedTable from "components/candidates/Engaged"
import Pagination from "components/talentData/Table/Pagination"
import CandidatesSidebar from "components/candidates/Sidebar"
import styles from "./engaged.module.scss"

const EngagedSourceCandidatesRoute = () => {
  const [connections, setConnections] = useState([])
  const [loading, setLoading] = useState(true)
  const [meta, setMeta] = useState({})
  const [showSidebar, setShowSidebar] = useState(false)
  const [currentSelected, setCurrentSelected] = useState(0)
  const [activeProfile, setActiveProfile] = useState([])

  const handleClose = () => {
    setShowSidebar(false)
  }

  const getCandidateProfile = uuid => {
    getCandidateProfileByUuid(uuid)
      .then(res => {
        setActiveProfile([res])
        setShowSidebar(true)
      })
      .catch(err => {
        handleClose()
        console.log(err)
        toast.custom(t => <ErrorToast t={t} headline="Something went wrong" />)
      })
  }

  const fetchConnections = useCallback((pageIndex = 1) => {
    const params = { page: pageIndex }

    getConnections(params)
      .then(res => {
        setConnections(res?.connections)
        setMeta(res?.meta)
      })
      .catch(e => {
        console.log("e", e)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const handleChange = useCallback(
    pageIndex => {
      fetchConnections(pageIndex)
    },
    [fetchConnections]
  )

  useEffect(() => {
    fetchConnections()
    pageCall("Engaged Candidates", "Sourcing")
  }, [])

  return (
    <div className="ads scroll-container">
      <Header title="Source Candidates" />

      <Content>
        <div className={styles.back}>
          <Link to="/source-candidates">
            <Icon name="back-arrow" height={12} width={14} />
            Back to Candidate Search
          </Link>
        </div>

        <div className={styles.titleWrapper}>
          <h3>Engaged Candidates</h3>
          <p>
            Manage your engaged candidates to optimize your candidate search
            experience.
          </p>
        </div>
        <div className={styles.wrapper}>
          <Link to="/source-candidates">
            <Button className={styles.topButton}>Find New Candidates</Button>
          </Link>

          {!loading ? (
            <EngagedTable
              connections={connections}
              getCandidateProfile={getCandidateProfile}
            />
          ) : (
            <div className="jobListingsLoader">
              <Spinner animation="border" />
            </div>
          )}

          {!loading && meta?.page < meta?.total_pages && (
            <div className="rv-tfoot">
              <Pagination
                handleChange={handleChange}
                meta={meta}
                sortBy={null}
                perPage={50}
              />
            </div>
          )}
        </div>
      </Content>

      <CandidatesSidebar
        show={showSidebar}
        onHide={handleClose}
        currentSelected={currentSelected}
        setCurrentSelected={setCurrentSelected}
        listCandidates={activeProfile}
        showFooter={false}
        engaged
      />
    </div>
  )
}

export default EngagedSourceCandidatesRoute
