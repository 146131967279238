import { useState } from "react"
import classNames from "classnames"
import { Accordion } from "react-bootstrap"
import { cleanTrim } from "utils/string"
import Icon from "components/Icon"
import FilterBadge from "components/shared/Filters/FilterBadge"

import "./AccordionFilter.scss"

const AccordionFilter = ({
  title,
  eventKey,
  defaultActive,
  body,
  watchedValues,
  removeFilter,
  type,
  className,
  headerClassName,

  icon = "triangle-down",
  showBadge = false,
  showTags = true,
}) => {
  const [isActive, setIsActive] = useState(defaultActive)

  return (
    <Accordion
      key={title}
      className={classNames("filter-accordion", className)}
    >
      <Accordion.Item eventKey={eventKey}>
        <Accordion.Header onClick={() => setIsActive(!isActive)}>
          <div
            className={classNames("filter-accordion-item", {
              "filter-accordion-item-active": isActive,
              [headerClassName]: headerClassName,
            })}
          >
            <div className={classNames("filter-accordion-header")}>
              <span>
                {title}{" "}
                {showBadge && watchedValues?.length > 0 && (
                  <span className="filter-accordion-header-count">
                    {watchedValues?.length}
                  </span>
                )}
              </span>
              <Icon name={icon} height={18} width={18} />
            </div>
          </div>
        </Accordion.Header>
        {watchedValues?.length > 0 && showTags && (
          <div className="filter-accordion-selected">
            {watchedValues?.length > 0 &&
              watchedValues.map((value, i) => (
                <FilterBadge
                  type={type}
                  value={cleanTrim(value?.name || value, 20, true)}
                  onClick={() => removeFilter(value)}
                  key={i}
                />
              ))}
          </div>
        )}
        <Accordion.Body>{body}</Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

export default AccordionFilter
