import { useTable, useFilters, useGlobalFilter, useSortBy } from "react-table"
import {
  DefaultFilterForColumn,
  SelectColumnFilter,
} from "components/shared/Table/Filter"
import { trackAction } from "utils/mixpanel"
import TableComponent from "components/shared/Table"
import Card from "components/shared/Card"

import EmptyTable from "components/shared/EmptyTable"
import styles from "./tableJobListings.module.scss"
import TableFiller from "components/jobListings/JobsListingsTable/Components/TableFiller"

const TableJobListing = ({
  columns,
  data,
  section = "Active",
  noJobs,
  freeTier,
  noMoreJobs,
}) => {
  const ACTION_NAME = "Call to Action Clicked"
  const TITLE_EMPTY = "No jobs matched your search"
  const TYPE = "Post a Job"

  const EMPTYSTATES = {
    Active: (
      <EmptyTable
        title={!noJobs ? TITLE_EMPTY : "You don’t have any active jobs yet"}
        description={
          noJobs &&
          "Jobs awaiting approval from our team will be listed here. Post a job to start receiving applicants from RepVue"
        }
        showButton={noJobs}
        buttonText="Post a Job"
        buttonUrl="/jobs/new"
        disabled={noMoreJobs}
        onClick={trackAction(ACTION_NAME, {
          location: "Job Listings Table (All Tab - Empty)",
          click_text: TYPE,
          click_url: `${process.env.REACT_APP_USER_URL}/jobs/new`,
          modal_name: null,
        })}
      />
    ),
    "Pending approval": (
      <EmptyTable
        title={!noJobs ? TITLE_EMPTY : "You have no jobs pending approval"}
        description={
          noJobs &&
          "Jobs awaiting approval from our team will be listed here. Post a job to start receiving applicants from RepVue"
        }
        showButton={noJobs}
        buttonText="Post a Job"
        buttonUrl="/jobs/new"
        disabled={noMoreJobs}
        onClick={trackAction(ACTION_NAME, {
          location: "Job Listings Table (Pending Tab - Empty)",
          click_text: TYPE,
          click_url: `${process.env.REACT_APP_USER_URL}/jobs/new`,
          modal_name: null,
        })}
      />
    ),
    Closed: (
      <EmptyTable
        title={!noJobs ? TITLE_EMPTY : "You have no closed jobs at the moment"}
        description={
          noJobs &&
          "Once a job posting is no longer active, it will appear here. You can review past postings or reactivate them if the position opens again"
        }
        showButton={noJobs}
        buttonText="Post a Job"
        buttonUrl="/jobs/new"
        disabled={noMoreJobs}
        onClick={trackAction(ACTION_NAME, {
          location: "Job Listings Table (Closed Tab - Empty)",
          click_text: TYPE,
          click_url: `${process.env.REACT_APP_USER_URL}/jobs/new`,
          modal_name: null,
        })}
      />
    ),
  }

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: data,
        defaultColumn: {
          Filter: SelectColumnFilter,
          DefaultFilter: DefaultFilterForColumn,
        },
      },
      useFilters,
      useGlobalFilter,
      useSortBy
    )

  return (
    <Card className={styles.tableContainerJobListings}>
      {data.length > 0 ? (
        <TableComponent
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          headerGroups={headerGroups}
          rows={rows}
          prepareRow={prepareRow}
          className={styles.tableContainerJobListings__table}
          filler={<TableFiller />}
          fillerCondition={freeTier && data?.length <= 2}
          headerFixed
        />
      ) : (
        EMPTYSTATES[section]
      )}
    </Card>
  )
}

export default TableJobListing
