import { getTalentProfile } from "api/talent"
import { LABELS_FILTERS_TALENT_PROFILE } from "constants/filters"
import { startCase } from "lodash"

export const createTalentDataQuery = (filters, page, sortBy) => {
  const requestObj = {
    page,
    per_page: filters.perPage,
    pivot_mode: "false",
  }

  if (sortBy.length && sortBy[0].id) {
    const sortObj = {}

    sortObj.col_id = sortBy[0].id
    sortObj.sort = sortBy[0].desc ? "desc" : "asc"

    requestObj.sort_model = [sortObj]
  }

  const handleNumberFilter = state => {
    return {
      filterType: "number",
      type: "inRange",
      filter: state.min,
      filter_to: state.max,
    }
  }

  const handleCategoryFilter = state => {
    return {
      filterType: "set",
      values: state,
    }
  }

  const filterObj = {}

  const numberFilters = {
    avgDealSize: {
      min: 0,
      max: 1000000,
      slug: "average_deal_size",
    },
    ote: {
      min: 0,
      max: 500000,
      slug: "average_ote",
    },
    topPerformerEarnings: {
      min: 0,
      max: 2000000,
      slug: "top_performer_earnings",
    },
    basePay: {
      min: 0,
      max: 300000,
      slug: "average_base_compensation",
    },
    productMarketFitScore: {
      min: 0,
      max: 5,
      slug: "average_product_market_fit_score",
    },
    diversityAndInclusionScore: {
      min: 0,
      max: 5,
      slug: "average_diversity_and_inclusion_score",
    },
    baseCompensationScore: {
      min: 0,
      max: 5,
      slug: "average_base_compensation_score",
    },
    inboundLeadScore: {
      min: 0,
      max: 5,
      slug: "average_inbound_lead_opportunity_flow_score",
    },
    incentiveCompensationStructureScore: {
      min: 0,
      max: 5,
      slug: "average_incentive_compensation_structure_score",
    },
    cultureScore: {
      min: 0,
      max: 5,
      slug: "average_culture_and_leadership_score",
    },
    professionalDevelopmentScore: {
      min: 0,
      max: 5,
      slug: "average_professional_development_and_training_score",
    },
    repVueScore: {
      min: 0,
      max: 100,
      slug: "repvue_score",
    },
    quotaAttainment: {
      min: 0,
      max: 100,
      slug: "average_quota_attainment",
    },
    peopleCount: {
      min: 0,
      max: 200,
      slug: "people_count",
    },
  }

  const categoryFilters = {
    selectedCompanies: {
      slug: "company_name",
      getValue: valueArray =>
        valueArray.map(value => (value.name ? value.name : value)),
    },
    headquarters: {
      slug: "headquarters_id",
      getValue: valueArray => valueArray.map(value => value.id),
    },
    selectedRoles: {
      slug: "user_role",
    },
    selectedIndustries: {
      slug: "industry_name",
    },
    selectedSegments: {
      slug: "sales_division",
    },
  }

  Object.keys(numberFilters).forEach(filter => {
    if (
      filters[filter].min > numberFilters[filter].min ||
      filters[filter].max > numberFilters[filter].max
    ) {
      filterObj[`${numberFilters[filter].slug}`] = handleNumberFilter(
        filters[filter]
      )
    }
  })

  Object.keys(categoryFilters).forEach(filter => {
    if (filters[filter]?.length) {
      const filterData = categoryFilters[filter]?.getValue
        ? categoryFilters[filter]?.getValue(filters[filter])
        : filters[filter]
      filterObj[`${categoryFilters[filter].slug}`] =
        handleCategoryFilter(filterData)
    }
  })

  if (filters.selectedPersonas.length) {
    requestObj.selling_persona_names = filters.selectedPersonas
  }

  if (Object.keys(filterObj).length > 0) {
    requestObj.filter_model = filterObj
  }

  if (filters.ratingsPeriod.value !== "all time") {
    requestObj.period = filters.ratingsPeriod.value
  }

  return requestObj
}

export const generateFilterBadges = filters => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  })

  const numberFilterArray = []
  const badgeArray = []
  const numberFilterMap = {
    average_deal_size: {
      minMessage: filter => `Avg. Deal Size > ${formatter.format(filter)}`,
      maxMessage: filter => `Avg. Deal Size < ${formatter.format(filter)}`,
      minValue: 0,
      maxValue: 1000000,
    },
    people_count: {
      minMessage: filter => `Respondents > ${filter}`,
      maxMessage: filter => `Respondents < ${filter}`,
      minValue: 0,
      maxValue: 200,
    },
    average_quota_attainment: {
      minMessage: filter => `Quota Attainment > ${filter}%`,
      maxMessage: filter => `Quota Attainment < ${filter}%`,
      minValue: 0,
      maxValue: 100,
    },
    average_ote: {
      minMessage: filter => `OTE > ${formatter.format(filter)}`,
      maxMessage: filter => `OTE < ${formatter.format(filter)}`,
      minValue: 0,
      maxValue: 500000,
    },
    top_performer_earnings: {
      minMessage: filter =>
        `Top Performer Earnings > ${formatter.format(filter)}`,
      maxMessage: filter =>
        `Top Performer Earnings < ${formatter.format(filter)}`,
      minValue: 0,
      maxValue: 2000000,
    },
    average_base_compensation: {
      minMessage: filter => `Base Comp > ${formatter.format(filter)}`,
      maxMessage: filter => `Base Comp < ${formatter.format(filter)}`,
      minValue: 0,
      maxValue: 300000,
    },
    average_culture_and_leadership_score: {
      minMessage: filter => `Culture Score > ${filter}`,
      maxMessage: filter => `Culture Score < ${filter}`,
      minValue: 0,
      maxValue: 5,
    },
    average_product_market_fit_score: {
      minMessage: filter => `Product Market Fit Score > ${filter}`,
      maxMessage: filter => `Product Market Fit Score < ${filter}`,
      minValue: 0,
      maxValue: 5,
    },
    repvue_score: {
      minMessage: filter => `RepVue Score > ${filter}`,
      maxMessage: filter => `RepVue Score < ${filter}`,
      minValue: 0,
      maxValue: 100,
    },
    average_diversity_and_inclusion_score: {
      minMessage: filter => `D&I Score > ${filter}`,
      maxMessage: filter => `D&I Score < ${filter}`,
      minValue: 0,
      maxValue: 5,
    },
    average_inbound_lead_opportunity_flow_score: {
      minMessage: filter => `Inbound Lead Score > ${filter}`,
      maxMessage: filter => `Inbound Lead Score < ${filter}`,
      minValue: 0,
      maxValue: 5,
    },
    average_incentive_compensation_structure_score: {
      minMessage: filter => `Comp Structure Score > ${filter}`,
      maxMessage: filter => `Comp Structure Score < ${filter}`,
      minValue: 0,
      maxValue: 5,
    },
    average_professional_development_and_training_score: {
      minMessage: filter => `Professional Development Score > ${filter}`,
      maxMessage: filter => `Professional Development Score < ${filter}`,
      minValue: 0,
      maxValue: 5,
    },
  }

  for (const filter in filters) {
    const {
      filter_type,
      filterType,
      values,
      filter: filterValue,
      filter_to,
    } = filters[filter]

    if (filter_type === "set" || filterType === "set") {
      badgeArray.push(values)
    }

    if (filter_type === "number" || filterType === "number") {
      const filterInfo = numberFilterMap[filter]

      if (filterInfo) {
        filterValue > filterInfo.minValue &&
          numberFilterArray.push(filterInfo.minMessage(filterValue))
        filter_to < filterInfo.maxValue &&
          numberFilterArray.push(filterInfo.maxMessage(filter_to))
      }
    }
  }

  badgeArray.push(numberFilterArray)

  return badgeArray
}

export const createInitialFilterState = (
  id,
  setFilterState,
  setTalentProfile
) => {
  let initialFilterState = {
    avgDealSize: { min: 0, max: 1000000 },
    ote: { min: 0, max: 500000 },
    topPerformerEarnings: { min: 0, max: 2000000 },
    basePay: { min: 0, max: 300000 },
    cultureScore: { min: 0, max: 5 },
    productMarketFitScore: { min: 0, max: 5 },
    repVueScore: { min: 0, max: 100 },
    quotaAttainment: { min: 0, max: 100 },
    peopleCount: { min: 0, max: 200 },
    baseCompensationScore: { min: 0, max: 5 },
    diversityAndInclusionScore: { min: 0, max: 5 },
    inboundLeadScore: { min: 0, max: 5 },
    incentiveCompensationStructureScore: { min: 0, max: 5 },
    professionalDevelopmentScore: { min: 0, max: 5 },
    selectedCompanies: [],
    selectedRoles: [],
    selectedPersonas: [],
    selectedIndustries: [],
    selectedSegments: [],
    headquarters: [],
    ratingsPeriod: { label: "All Time", value: "all time" },
    perPage: 100,
  }

  if (!id) return setFilterState(initialFilterState)

  getTalentProfile(id).then(({ data }) => {
    const {
      filter: { filter_model, period, selling_persona_names },
    } = data

    setTalentProfile(data)

    const appliedFilters = filter_model || data.filter

    const filteredHeadquarters = data.filter.headquarters

    if (period) {
      const periodLabel = startCase(period)
      initialFilterState.ratingsPeriod = {
        label: periodLabel,
        value: period,
      }
    }

    if (selling_persona_names) {
      initialFilterState.selectedPersonas = selling_persona_names
    }

    for (let filter in appliedFilters) {
      const TYPE = appliedFilters[filter]?.filter_type
      const label = LABELS_FILTERS_TALENT_PROFILE[filter]

      TYPE === "set"
        ? (initialFilterState[label] = appliedFilters[filter]?.values)
        : (initialFilterState[label] = {
            min: appliedFilters[filter]?.filter,
            max: appliedFilters[filter]?.filter_to,
          })
    }

    if (filteredHeadquarters)
      initialFilterState.headquarters = filteredHeadquarters

    setFilterState(initialFilterState)
  })
}

export const calculateFilterCount = (
  filterState,
  currentPageIndex,
  currentSort
) => {
  const filters = createTalentDataQuery(
    filterState,
    currentPageIndex,
    currentSort
  )
  const activeFilters = filters?.filter_model || {}

  let total = 0
  if (filters.selling_persona_names)
    total += filters.selling_persona_names.length

  const maxValues = {
    average_deal_size: 1000000,
    average_ote: 500000,
    top_performer_earnings: 2000000,
    average_base_compensation: 300000,
    average_quota_attainment: 100,
    repvue_score: 100,
    people_count: 200,
    average_professional_development_and_training_score: 5,
  }

  for (const filter in activeFilters) {
    const {
      filterType,
      filter: filterValue,
      filter_to: filterTo,
      values,
    } = activeFilters[filter] || {}

    if (
      filterType === "number" &&
      (filterValue > 0 || filterTo < maxValues[filter])
    )
      total++
    if (filterType === "set") total += values.length
  }

  return total
}
