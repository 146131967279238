import styles from "./SwitchFilter.module.scss"

const SwitchFilter = ({ setFilters, filters, label, name }) => {
  const handleChange = e => {
    setFilters({ ...filters, [e.target.name]: e.target.checked })
  }

  return (
    <article className={styles.switchFilter}>
      <span className={styles.switchFilter__label}>{label}</span>
      <label className={styles.switchFilter__switch}>
        <input
          type="checkbox"
          className={styles.switchFilter__input}
          onChange={handleChange}
          name={name}
          checked={filters[name] || false}
        />
        <span
          className={`${styles.switchFilter__slider} ${styles.switchFilter__slider_round}`}
        ></span>
      </label>
    </article>
  )
}

export default SwitchFilter
