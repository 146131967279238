import { useMemo } from "react"
import dayjs from "dayjs"
import { useTable, useFilters, useGlobalFilter, useSortBy } from "react-table"
import {
  DefaultFilterForColumn,
  SelectColumnFilter,
} from "components/shared/Table/Filter"
import TableComponent from "components/shared/Table"
import Card from "components/shared/Card"

import styles from "./Table.module.scss"

const EngagedTable = ({ data, getCandidateProfile }) => {
  const columns = useMemo(
    () => [
      {
        Header: "Connection Date",
        hideHeader: true,
        columns: [
          {
            Header: "Connection Date",
            accessor: "created_at",
            sortable: true,
            filterable: false,
            Cell: ({ cell: { value } }) => {
              const formattedDate = dayjs(new Date(Date.parse(value))).format(
                "MMM DD, YYYY"
              )
              if (formattedDate === "Invalid Date") {
                return "--"
              }
              return formattedDate
            },
            width: "flex",

            colSpan: 1,
            className: styles.date,
          },
          {
            Header: "Candidate Name",
            accessor: "name",
            sortable: true,
            filterable: false,
            Cell: ({ cell: { value } }) => {
              return <span className={styles.nameCell}>{value}</span>
            },
            width: "flex",
            colSpan: 1,
            className: styles.tableCell,
          },

          {
            Header: "Current Company",
            accessor: "company_name",
            sortable: true,
            filterable: false,
            Cell: ({ cell: { value } }) => {
              return <span className={styles.nameCell}>{value || "--"}</span>
            },
            width: "flex",
            colSpan: 1,
            className: styles.tableCell,
          },

          {
            Header: "Job Connection",
            accessor: "job_title",
            sortable: true,
            filterable: false,
            Cell: ({ cell: { value } }) => {
              return <span className={styles.jobConnection}>{value}</span>
            },
            width: "flex",
            className: styles.tableCell,
          },

          {
            Header: "Candidate Email",
            accessor: "email",
            sortable: true,
            filterable: false,
            colSpan: 2,
            Cell: ({ cell: { value } }) => {
              return <span className={styles.jobConnection}>{value}</span>
            },
            width: "flex",
            className: styles.activeCell,
          },

          {
            Header: "Recruiter",
            accessor: "employer_name",
            sortable: true,
            filterable: false,
            Cell: ({ cell: { value } }) => {
              return <span className={styles.nameCell}>{value || "--"}</span>
            },
            width: "flex",
            colSpan: 1,
            className: styles.tableCell,
          },
        ],
      },
    ],
    [data]
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        defaultColumn: {
          Filter: SelectColumnFilter,
          DefaultFilter: DefaultFilterForColumn,
        },
        initialState: {
          sortBy: [
            {
              id: "created_at",
              desc: true,
            },
          ],
        },
      },
      useFilters,
      useGlobalFilter,
      useSortBy
    )

  const onRowClick = record => {
    getCandidateProfile(record?.profile?.uuid)
  }

  return (
    <Card className={styles.table}>
      {data.length > 0 && (
        <TableComponent
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          headerGroups={headerGroups}
          rows={rows}
          prepareRow={prepareRow}
          onRowClick={onRowClick}
        />
      )}
    </Card>
  )
}

export default EngagedTable
