import CardExperience from "./CardExperience"
import styles from "./salesExperience.sidebar.candidates.module.scss"

const SalesExperience = ({ salesExperience = [] }) => {
  return (
    <article className={styles.salesExperience}>
      <span className={styles.salesExperience__title}>Sales Experience</span>
      {salesExperience?.map((experience, index) => (
        <CardExperience
          key={experience?.id}
          current={index === 0}
          experience={experience}
        />
      ))}
    </article>
  )
}

export default SalesExperience
