import apiCall from "utils/api"

export const getCandidateProfiles = params => {
  const options = { params }
  return apiCall("get", `candidate/profiles`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      throw error
    })
}

export const getFilterOptions = () => {
  return apiCall("get", `candidate/profiles/filter_options`)
    .then(response => {
      return response.data
    })
    .catch(error => {
      console.error("Candidate Profile Filter Options", error)
      throw error
    })
}

export const connectWithCandidate = (uuid, params) => {
  const options = { data: params }

  return apiCall("post", `candidate/profiles/${uuid}/connections`, options)
    .then(response => {
      return response.data
    })
    .catch(error => {
      console.error("Candidate Profile Connection", error)
      throw error
    })
}

export const getCandidateProfileByUuid = uuid => {
  return apiCall("get", `candidate/profiles/${uuid}`)
    .then(response => {
      return response.data
    })
    .catch(error => {
      console.error("Candidate Profile", error)
      throw error
    })
}

export const getConnections = params => {
  const options = { data: params }
  return apiCall("get", `candidate/connections`, options)
    .then(response => {
      return response.data
    })
    .catch(error => {
      console.error("Candidate Profile Connections", error)
      throw error
    })
}
