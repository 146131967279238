import { getSoldToLabels } from "utils/candidateProfile"
import { getCurrencyFormat } from "utils/string"
import Tag from "./Tag"
import styles from "./tags.cardExperience.module.scss"

const TagsCardExperience = ({ experience }) => {

  const { average_deal_size, sales_cycle_length, selling_personas } = experience

  return (
    <div className={styles.tagsCardExperience}>
      {selling_personas?.length > 0 && (
        <Tag label="Sold to" value={getSoldToLabels(experience)} />
      )}

      {sales_cycle_length && (
        <Tag
          label="Sales Cycle Length"
          value={sales_cycle_length}
        />
      )}

      {average_deal_size &&
        <Tag
          label="Avg. Deal Size"
          value={getCurrencyFormat(average_deal_size)}
        />
      }
    </div>
  )
}

export default TagsCardExperience
