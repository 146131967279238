import apiCall from "utils/api"

export const getMetroLocations = search => {
  const options = {
    params: {
      q: search,
    },
  } 
  return apiCall("get", `/metro_locations`, options)
    .then(response => {
      return response.data
    })
    .catch(error => {
      console.error("Metro Locations", error)
      throw error
    })
}

export const getMetroLocationsSansCities = search => {
  const options = {
    params: {
      q: search,
    },
  } 
  return apiCall("get", `/metro_locations/metros`, options)
    .then(response => {
      return response.data
    })
    .catch(error => {
      console.error("Metro Locations", error)
      throw error
    })
}
