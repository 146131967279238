import cn from "classnames"
import Icon from "components/Icon"
import styles from "./tag.candidateProfile.module.scss"

const TagLinkResume = ({
  icon,
  label,
  widthIcon = "16",
  heightIcon = "16",
  url,
}) => {
  return (
    <article className={styles.tag}>
      <a
        href={url}
        target="_blank"
        rel="noreferrer"
        className={cn({ [styles.tag__empty]: !url })}
      >
        <Icon
          className={styles.tag__icon}
          name={icon}
          height={widthIcon}
          width={heightIcon}
        />

        <span className={styles.tag__label}>{label}</span>
      </a>
    </article>
  )
}

export default TagLinkResume
