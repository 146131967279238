import { useState, useEffect } from "react"
import toast from "react-hot-toast"
import { connectWithCandidate } from "api/candidate/profiles"
import { pageCall } from "utils/mixpanel"
import { trackAction } from "utils/mixpanel"
import Offcanvas from "react-bootstrap/Offcanvas"
import ErrorToast from "components/shared/Notifications/ErrorToast"
import SuccessToast from "components/shared/Notifications/SuccessToast"
import FooterCard from "./Footer"
import CardContent from "./CardContent"
import ActionsSidebar from "./Actions"
import CandidateConnect from "./CandidateConnect"

import styles from "./sidebar.candidates.module.scss"

const CandidatesSidebar = ({
  show,
  onHide,
  currentSelected,
  setCurrentSelected,
  listCandidates = [],
  updateCandidate,
  metaInfo,
  fetchCandidates,
  jobs,
  updateUrl,
  currentEmployer,
  schedulingLink,
  openSchedulingLinkModal,
  loadingListCandidates,
  showFooter = true,
  engaged = false
}) => {
  const [activeCardIndex, setActiveCardIndex] = useState(0)
  const [showCandidateConnect, setShowCandidateConnect] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})

  const changeActiveCard = up => {
    setErrors({})
    setCurrentSelected(null)
    setShowCandidateConnect(false)
    setActiveCardIndex(state =>
      up
        ? state < listCandidates?.length - 1
          ? state + 1
          : listCandidates.length - 1
        : state > 0
        ? state - 1
        : 0
    )
  }

  const saveCandidate = () => {
    updateCandidate(activeCardIndex)
  }

  const showCandidateConnectHandler = (show = true) => {
    setShowCandidateConnect(show)
    trackAction("Call to Action Clicked", {
      location: "Candidate Detail",
      click_text: "Connect With Candidate",
      click_url: null,
      modal_name: null,
    })
  }

  const hadleOnHide = () => {
    setErrors({})
    setShowCandidateConnect(false)
    onHide()
  }

  const scrollToElement = (elementId, smooth = true) => {
    const element = document.getElementById(elementId)
    if (element)
      element.scrollIntoView({
        behavior: smooth ? "smooth" : "auto",
        block: "start",
      })
  }

  const getMoreCandidates = () => {
    if (metaInfo?.next_page) fetchCandidates(metaInfo?.next_page)
  }

  const connectCandidate = (uuid, data, title, fullName) => {
    setLoading(true)
    connectWithCandidate(uuid, data)
      .then(res => {
        const connection = {
          engaged: true,
          last_connection: {
            created_at: res?.created_at,
            employer: {
              first_name: currentEmployer?.first_name,
              last_name: currentEmployer?.last_name,
            },
            job: {
              title: title,
            },
          },
        }
        trackAction("Candidate Connection Sent", {
          company_name: currentEmployer?.company?.name,
          job_posting: title,
          candidate_name: fullName,
        })
        updateCandidate(activeCardIndex, connection)

        toast.custom(t => (
          <SuccessToast
            t={t}
            headline="We've sent a message to this candidate and saved them in your Engaged Candidates."
          />
        ))

        hadleOnHide()
      })
      .catch(err => {
        console.log(err)
        setErrors(err?.data)
        toast.custom(t => <ErrorToast t={t} headline="Something went wrong" />)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (currentSelected !== null && currentSelected >= 0) {
      setActiveCardIndex(currentSelected)
      setTimeout(() => {
        scrollToElement(currentSelected, false)
      }, 100)
    }
  }, [currentSelected, show])

  useEffect(() => {
    scrollToElement(activeCardIndex)
    if (show) updateUrl(listCandidates?.[activeCardIndex]?.uuid)
  }, [activeCardIndex])

  useEffect(() => {
    if (show) {
      const viewedCandidate = listCandidates[activeCardIndex]
      pageCall(engaged ? "Candidate Connection"  : "Candidate Detail", "Sourcing", {
        candidate_name: `${viewedCandidate?.first_name} ${viewedCandidate?.last_name}`,
        years_of_experience: viewedCandidate?.years_selling_experience,
        current_company: viewedCandidate?.latest_company_name,
        current_role: viewedCandidate?.role,
        preferred_locations: viewedCandidate?.metro_locations
          ?.map(location => location.name)
          .join(", "),
      })
    }
  }, [activeCardIndex])

  return (
    <section className={styles.sidebar}>
      <Offcanvas
        show={show}
        onHide={hadleOnHide}
        placement="end"
        backdrop
        className={styles.sidebar__main}
        backdropClassName={styles.sidebar__backdrop}
      >
        <ActionsSidebar
          onHide={hadleOnHide}
          allowArrows={listCandidates?.length > 1}
          changeActiveCard={changeActiveCard}
          loadingListCandidates={loadingListCandidates}
        />

        <Offcanvas.Body className={styles.sidebar__content}>
          <div className={styles.sidebar__container}>
            {listCandidates?.map((candidate, index) => (
              <CardContent
                id={index}
                key={`${candidate?.id}-canidate-sidebar-${index}`}
                candidate={candidate}
                candidatesCount={listCandidates?.length}
                getMoreCandidates={getMoreCandidates}
              />
            ))}
          </div>
          {showFooter && (
            <FooterCard
              id={listCandidates?.[activeCardIndex]?.id}
              save={saveCandidate}
              candidateConnect={showCandidateConnectHandler}
            />
          )}

          <CandidateConnect
            uuid={listCandidates?.[activeCardIndex]?.uuid}
            name={listCandidates?.[activeCardIndex]?.first_name}
            fullName={`${listCandidates?.[activeCardIndex]?.first_name} ${listCandidates?.[activeCardIndex]?.last_name}`}
            jobs={jobs}
            show={showCandidateConnect}
            hide={() => showCandidateConnectHandler(false)}
            connectCandidate={connectCandidate}
            loading={loading}
            errors={errors}
            setErrors={setErrors}
            schedulingLink={schedulingLink}
            openSchedulingLinkModal={openSchedulingLinkModal}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </section>
  )
}

export default CandidatesSidebar
