import { useMemo, useState } from "react"
import cn from "classnames"
import debounce from "lodash/debounce"

import Icon from "components/Icon"
import Form from "react-bootstrap/Form"

import styles from "./CompanySearch.module.scss"

const CompanySearch = ({
  companies,
  handleSearch,
  handleSelect,
  selectedCompanies,
  placeholder = "Search sales orgs",
}) => {
  const [value, setValue] = useState("")

  const debounceHandleSearch = useMemo(
    () => debounce(handleSearch, 200),
    [handleSearch]
  )

  const changeValue = value => {
    setValue(value)
    debounceHandleSearch(value)
  }

  return (
    <div
      className={cn(`${styles.search}`, {
        [`${styles.search__hasResults}`]: companies?.length > 11,
      })}
    >
      <div className={styles.inputWrapper}>
        <Icon
          name="search"
          height="20"
          width="20"
          className={styles.iconLeft}
        />
        <Form.Control
          className={styles.input}
          type="search"
          placeholder={placeholder}
          name="search"
          onChange={e => changeValue(e.target.value)}
        />
      </div>

      {companies?.length ? (
        <div className={styles.results}>
          {companies?.map(company => {
            const isSelected = selectedCompanies?.some(
              selected =>
                selected?.name === company?.name &&
                selected?.slug === company?.slug
            )

            return (
              <div
                className={styles.results__selection}
                onClick={() => {
                  handleSelect(company)
                }}
                key={companies?.indexOf(company)}
              >
                {isSelected ? (
                  <Icon name="checkbox" height={18} width={18} />
                ) : (
                  <div className={styles.results__checkbox} />
                )}
                {isSelected ? (
                  <strong className={styles.results__selected}>
                    {company.name}
                  </strong>
                ) : (
                  company.name
                )}
              </div>
            )
          })}
        </div>
      ) : (
        <div className={styles.results__selection}>
          {value?.length > 0 && "No results were found."}
        </div>
      )}
    </div>
  )
}

export default CompanySearch
