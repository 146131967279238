import styles from "./ratings.sidebar.candidates.module.scss"

const RatingsCard = ({ candidate }) => {
  const ranking = candidate?.rankings?.slice(-1)[0]

  return (
    <article className={styles.myRankings}>
      <span className={styles.myRankings__title}>
        What {candidate?.first_name} Values Most in their Selling Environment
      </span>

      <div className={styles.myRankings__tags}>
        {ranking?.metric_rankings?.slice(0, 3).map((category, index) => (
          <div
            className={styles.myRankings__ranking}
            key={`${category?.label}${index}`}
          >
            <span className={styles.myRankings__ranking__number}>
              {index + 1}
            </span>
            <span className={styles.myRankings__ranking__category}>
              {category?.metric}
            </span>
          </div>
        ))}
      </div>
    </article>
  )
}

export default RatingsCard
