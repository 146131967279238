import { getDate } from "utils/string"
import styles from "./contactedLabel.candidateProfile.module.scss"

const ConnectionLabelCandidate = ({ connection }) => {
  return (
    <span className={styles.connectionLabel}>
      Contacted on {getDate(connection?.created_at)} at{" "}
      <span className={styles.connectionLabel__title}>
        {connection?.job?.title}
      </span>{" "}
      by {connection?.employer?.first_name} {connection?.employer?.last_name}
    </span>
  )
}

export default ConnectionLabelCandidate
