import { useCallback, useState, useMemo, useEffect } from "react"
import createNumberMask from "text-mask-addons/dist/createNumberMask"
import debounce from "lodash/debounce"

import SliderRange from "components/shared/Slider"
import MaskedInput from "react-text-mask"

import styles from "./SliderSelect.module.scss"

const SliderSelect = ({
  min,
  max,
  step,
  setFilter,
  filter,
  filters,
  labelMin,
  labelMax,
  type,
  inputTye,
  options,
}) => {
  const [localRange, setLocalRange] = useState({ min: min, max: max })

  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  })

  const currencyMaskOptions = {
    prefix: "$",
    suffix: "",
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ",",
    allowDecimal: false,
    integerLimit: 7, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: false,
  }

  const percentageMaskOptions = {
    prefix: "",
    suffix: "%",
    allowDecimal: false,
    integerLimit: 3,
    allowNegative: false,
    allowLeadingZeroes: false,
  }

  const SliderInput = ({ ...inputProps }) => {
    const currencyMask = createNumberMask(currencyMaskOptions)
    const percentageMask = createNumberMask(percentageMaskOptions)
    const { type } = inputProps
    return (
      <MaskedInput
        {...inputProps}
        mask={type === "currency" ? currencyMask : percentageMask}
      />
    )
  }

  const handleMin = useCallback(
    e => {
      setFilter({
        ...filters,
        [`${filter}`]: {
          min:
            e.target.value === ""
              ? 0
              : Number(e.target.value.replace(/[^0-9.-]+/g, "")),
          max: localRange.max,
        },
      })
      setLocalRange({
        ...localRange,
        min: parseInt(
          e.target.value === ""
            ? 0
            : Number(e.target.value.replace(/[^0-9.-]+/g, ""))
        ),
      })
    },
    [filter, filters, localRange, setFilter]
  )

  const handleMax = useCallback(
    e => {
      setFilter({
        ...filters,
        [`${filter}`]: {
          min: localRange.min,
          max:
            e.target.value === ""
              ? 0
              : Number(e.target.value.replace(/[^0-9.-]+/g, "")),
        },
      })
      setLocalRange({
        ...localRange,
        max: parseInt(
          e.target.value === ""
            ? 0
            : Number(e.target.value.replace(/[^0-9.-]+/g, ""))
        ),
      })
    },
    [filter, filters, localRange, setFilter]
  )

  const debounceHandleMin = useMemo(() => debounce(handleMin, 375), [handleMin])

  const debounceHandleMax = useMemo(() => debounce(handleMax, 375), [handleMax])

  useEffect(() => {
    if (filters[`${filter}`].max === max) {
      if (filters[`${filter}`].min === 0) {
        setLocalRange({ min: min, max: max })
      } else {
        setLocalRange({ ...localRange, max: max })
      }
    } else if (filters[`${filter}`].min === 0) {
      if (filters[`${filter}`].max === max) {
        setLocalRange({ min: min, max: max })
      } else {
        setLocalRange({ ...localRange, min: min })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  return (
    <div className={styles.slider}>
      <div className={styles.display}>
        {type ? (
          <SliderInput
            type={type}
            className={styles.display__window}
            onChange={debounceHandleMin}
            placeholder={
              type === "currency"
                ? currencyFormatter.format(localRange.min)
                : type === "percentage"
                ? `${localRange.min}%`
                : localRange.min
            }
          />
        ) : (
          <input
            type={inputTye}
            className={styles.display__window}
            value={options ? options[localRange.min] : localRange.min}
            onChange={e => {
              const min = e.target.value === "" ? 0 : e.target.value
              if (e.target.value) {
                setFilter({
                  ...filters,
                  [`${filter}`]: {
                    min: min,
                    max: localRange.max,
                  },
                })
              }
              setLocalRange({
                ...localRange,
                min: min,
              })
            }}
            placeholder={localRange.min}
            disabled={inputTye === "text"}
          />
        )}
        to
        {type ? (
          <SliderInput
            className={styles.display__window}
            type={type}
            onChange={debounceHandleMax}
            placeholder={
              type === "currency"
                ? currencyFormatter.format(localRange.max)
                : type === "percentage"
                ? `${localRange.max}%`
                : localRange.max
            }
          />
        ) : (
          <input
            type={inputTye}
            className={styles.display__window}
            onChange={e => {
              const max = e.target.value === "" ? 0 : e.target.value
              if (e.target.value) {
                setFilter({
                  ...filters,
                  [`${filter}`]: {
                    min: localRange.min,
                    max: max,
                  },
                })
              }
              setLocalRange({
                ...localRange,
                max: max,
              })
            }}
            disabled={inputTye === "text"}
            placeholder={localRange.max}
            value={options ? options[localRange.max] : localRange.max}
          />
        )}
      </div>
      <div className={styles.slider__wrapper}>
        <span>{labelMin}</span>
        <SliderRange
          min={min}
          max={max}
          step={step}
          onChange={params => {
            const [min, max] = params
            setLocalRange({ min: min, max: max })
          }}
          onAfterChange={params => {
            const [min, max] = params
            setFilter({
              ...filters,
              [`${filter}`]: {
                min: min,
                max: max,
              },
            })
          }}
          localRange={localRange}
        />
        <span>{labelMax}</span>
      </div>
    </div>
  )
}

export default SliderSelect
