import { useRef, useEffect, memo } from "react"
import useIsInViewport from "hooks/useIsInViewport"
import HeaderCard from "./Header"
import SalesExperience from "./SalesExperience"
import PreferencesCard from "./Preferences"
import LabelsCard from "./Labels"
import RatingsCard from "./Ratings"
import styles from "./cardContent.sidebar.candidates.module.scss"

const CardContent = ({ id, candidate, candidatesCount, getMoreCandidates }) => {
  const card = useRef()
  const isInViewport = useIsInViewport(card, {
    root: null,
    rootMargin: "50px",
    threshold: 1,
  })

  useEffect(() => {
    const candidateIndex = candidatesCount - 1
    const endCardId = Number(card.current.id)
    if (endCardId === candidateIndex && isInViewport) getMoreCandidates()
  }, [isInViewport])

  return (
    <article className={styles.cardContent} id={id} ref={card}>
      <article className={styles.cardContent__container}>
        <LabelsCard candidate={candidate} />

        <HeaderCard candidate={candidate} />

        <SalesExperience salesExperience={candidate?.sales_experiences} />

        <PreferencesCard candidate={candidate} />

        <RatingsCard candidate={candidate} />
      </article>
    </article>
  )
}

export default memo(CardContent)
