import cn from "classnames"
import { Button, Spinner } from "react-bootstrap"
import Icon from "components/Icon"
import styles from "./actions.sidebar.candidates.module.scss"

const ActionsSidebar = ({
  onHide,
  changeActiveCard,
  allowArrows,
  loadingListCandidates,
}) => {
  return (
    <article className={styles.actions}>
      {allowArrows && (
        <>
          <Button
            className={cn(styles.actions__button, styles.actions__button__up)}
            onClick={() => changeActiveCard()}
          >
            <Icon name="arrow-up" width={20} height={20} />
          </Button>
          <Button
            disabled={loadingListCandidates}
            className={cn(styles.actions__button, styles.actions__button__down)}
            onClick={() => changeActiveCard(true)}
          >
            {loadingListCandidates ? (
              <Spinner animation="border" size="sm" />
            ) : (
              <Icon name="arrow-up" width={20} height={20} />
            )}
          </Button>
        </>
      )}
      <div
        className={cn(styles.actions__button, styles.actions__button__close)}
        onClick={onHide}
      >
        <Icon name="close-bold" width={18} height={18} />
      </div>
    </article>
  )
}

export default ActionsSidebar
