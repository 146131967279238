import cn from "classnames"
import styles from "./Card.module.scss"

function Card({ children, className, onClick }) {
  return (
    <div onClick={onClick} className={cn(styles.card, className)}>
      {children}
    </div>
  )
}

export default Card
