import { useEffect, useState, useCallback, useMemo } from "react"
import { Row, Col, Spinner, ProgressBar } from "react-bootstrap"
import { getJobs } from "api/jobs"
import { useSelector } from "react-redux"
import { pageCall } from "utils/mixpanel"
import classNames from "classnames"
import { useSearchParams } from "react-router-dom"
import { trackAction } from "utils/mixpanel"

import ContactUsLink from "components/shared/ContactUsLink"
import Header from "components/Header"
import Content from "components/Content"
import JobsListingsTable from "components/jobs/jobListings/JobsListingsTable"
import HeaderActions from "components/jobs/jobListings/HeaderActions"
import Pagination from "components/talentData/Table/Pagination"
import JobsTabs from "components/jobs/jobListings/JobsTabs"
import { GlobalFilter } from "components/shared/Table/Filter"
import CheckboxFilter from "components/shared/Table/CheckboxFilter"
import ApplicantPreferenceModal from "components/jobListings/ApplicantPreferenceModal"

import "./Listings.scss"

const JobListingsRoute = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const [displayedJobs, setDisplayedJobs] = useState([])
  const [loading, setLoading] = useState(true)
  const [meta, setMeta] = useState({})
  const [status, setStatus] = useState(searchParams?.get("status") || "Active")
  const [activeSearch, setActiveSearch] = useState("")
  const [recruiters, setRecruiters] = useState([])
  const [currentSort] = useState(null)
  const [noJobs, setNoJobs] = useState(false)

  const { currentEmployer } = useSelector(state => state.auth)
  const { job_board, customer } = currentEmployer
  const { access_type, jobs_remaining } = customer
  const atsIntegrated = currentEmployer.company.integration

  const freeTier = access_type === "free"

  const LOCATION = useMemo(
    () => ({
      fully_remote: "Remote",
      remote_in_territory: "Remote in Territory",
      in_office_hybrid: "In Office/Hybrid",
    }),
    []
  )

  const oteFormatter = num => {
    return Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "K"
      : Math.sign(num) * Math.abs(num)
  }

  const fetchJobs = useCallback(
    (
      pageIndex = 1,
      sortBy = currentSort,
      search = activeSearch,
      tab = status,
      initial = false,
      recruitersFilter = null
    ) => {
      if (initial) {
        setLoading(true)
      }

      setStatus(tab)
      setSearchParams({ status: tab })

      const params = { page: pageIndex, status: tab }

      if (sortBy?.length && sortBy[0].id) {
        params.sort_key = sortBy[0].id
        params.sort_direction = sortBy[0].desc ? "desc" : "asc"
      }

      if (search?.length) {
        params.search = search
      }

      if (recruitersFilter && recruitersFilter !== recruiters) {
        params["employers.id"] = recruitersFilter.map(recruiter => recruiter.id)
      }

      getJobs(params)
        .then(res => {
          const { jobs, meta } = res.data
          setDisplayedJobs(jobs)
          let jobsListing = {}
          jobs?.forEach(job => {
            if (!!!jobsListing[job.status]) jobsListing[job.status] = []
            job.location_type = LOCATION[job.location_type]
            if (job.ote_min > 0 || job.ote_max > 0)
              job.oteRange = `$${oteFormatter(job.ote_min)} - ${oteFormatter(
                job.ote_max
              )}`

            job.recruiters = job?.employers.map(
              employer => `${employer.first_name} ${employer.last_name}`
            )

            jobsListing[job.status].push(job)
          })
          setMeta(meta)
          setLoading(false)
          setNoJobs(jobs.length === 0 && !activeSearch)
          if (initial) {
            setRecruiters(res?.data?.meta?.employers)
          }
        })
        .catch(err => {
          console.log("err", err)
          setLoading(false)
        })
    },
    [LOCATION, status, activeSearch, currentSort, recruiters]
  )

  const handleChange = useCallback(
    pageIndex => {
      fetchJobs(pageIndex)
    },
    [fetchJobs]
  )

  useEffect(() => {
    if (!loading) {
      fetchJobs()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSearch])

  useEffect(() => {
    fetchJobs(1, currentSort, activeSearch, status, true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    pageCall("Job Listings", "Jobs", { listings_tab: status })
  }, [status])

  return (
    <div className="jobListings">
      <Header title="Job Listings" />

      <Content>
        <div className="jobListingsHeader">
          <h3>Job Listings</h3>
          <HeaderActions jobsRemaining={jobs_remaining} />
        </div>
        <div className="jobListingsSubHeader">
          <span className="jobListingsSubHeader__access">
            Manage your job listings and review applicants.
          </span>
          {freeTier && (
            <>
              <span className="jobListingsSubHeader__count">{`${
                2 - jobs_remaining
              }/2 Free Jobs`}</span>
              <ProgressBar
                now={jobs_remaining === 0 ? 100 : jobs_remaining === 1 ? 50 : 0}
                className={classNames({
                  jobListingsSubHeader__progress: jobs_remaining > 0,
                  jobListingsSubHeader__full: jobs_remaining === 0,
                })}
              />
              <span
                className={classNames({
                  jobListingsSubHeader__available: jobs_remaining > 0,
                  jobListingsSubHeader__unavailable: jobs_remaining === 0,
                })}
              >
                {`${jobs_remaining} remaining!`}
              </span>
              <ContactUsLink
                hasIcon
                handleCTAClick={() => {
                  trackAction("Call to Action Clicked", {
                    location: "Job Listings",
                    click_text: "Upgrade Now!",
                    click_url: "https://www.repvue.com/contact",
                    modal_name: null,
                  })
                }}
              >
                Upgrade Now
              </ContactUsLink>
            </>
          )}
        </div>
        <Row>
          <Col lg={12}>
            <div className="wrapper">
              <JobsTabs
                fetchJobs={fetchJobs}
                counts={meta.job_counters}
                activeTab={status}
                activeSearch={""}
                atsIntegrated={atsIntegrated}
              />
              <div className="jobs-controls">
                <GlobalFilter
                  globalFilter={activeSearch}
                  setGlobalFilter={setActiveSearch}
                  placeholder={
                    activeSearch?.length ? activeSearch : "Search Jobs"
                  }
                />
                <CheckboxFilter
                  icon="people"
                  update={fetchJobs}
                  data={recruiters}
                  placeholder="All Recruiters"
                />
              </div>
              {!loading ? (
                <JobsListingsTable
                  jobs={displayedJobs}
                  section={status}
                  setJobs={setDisplayedJobs}
                  noJobs={noJobs}
                  activeJobsCount={meta?.job_counters?.active_jobs_count}
                />
              ) : (
                <div className="jobListingsLoader">
                  <Spinner animation="border" />
                </div>
              )}
              {!loading && (
                <div className="rv-tfoot">
                  <Pagination
                    handleChange={handleChange}
                    meta={meta.page_info}
                    sortBy={null}
                    perPage={50}
                  />
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Content>
      {!job_board && access_type === "free" && <ApplicantPreferenceModal />}
    </div>
  )
}

export default JobListingsRoute
