import Card from "components/shared/Card"
import Icon from "components/Icon"
import styles from "./emptyState.candidate.module.scss"

const EmptyState = () => {
  return (
    <Card className={styles.emptyState}>
      <div className={styles.emptyState__icon}>
        <Icon name="audience" height="50" width="50" />
      </div>
      <h6>There aren&apos;t any candidates that match your criteria</h6>
      <p className={styles.emptyState__description}>
        Want us to find candidates that fit this criteria? Fill out our{" "}
        <a
          href="https://app.opinionx.co/repvue-sourcing-beta"
          target="_blank"
          rel="noreferrer"
        >
          survey here
        </a>{" "}
        and we'll work on adding more candidates that fit your criteria!
      </p>
    </Card>
  )
}

export default EmptyState
