import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, Navigate, useLocation } from "react-router-dom"
import { signIn } from "api/auth"
import qs from "qs"
import { pageCall } from "utils/mixpanel"

import Icon from "components/Icon"
import PrimaryButton from "components/shared/Buttons/Primary"
import Error from "components/shared/Notifications/Error"
import PasswordField from "components/shared/Forms/PasswordField"
import Success from "components/shared/Notifications/Success"

import "./SignIn.scss"

const SignInRoute = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const { authenticated } = useSelector(state => state.auth)

  const queryStr = qs.parse(window.location.search)
  const success = queryStr.success

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const handleSignIn = e => {
    e.preventDefault()
    setLoading(true)
    setError(false)

    const params = { employer: { email, password } }
    dispatch(signIn(params)).catch(() => {
      setError(true)
      setLoading(false)
    })
  }

  useEffect(() => {
    pageCall("Login Page", "Sign In")
  }, [])

  if (authenticated) {
    const path = location.state || "/dashboard"
    return <Navigate to={path} replace />
  }

  return (
    <div className="sign-in">
      <h5>Welcome Back to RepVue!</h5>
      <p>Sign into your account to continue.</p>

      {error && <Error>Incorrect email or password</Error>}
      {success && <Success>Your password has been reset!</Success>}

      <form onSubmit={handleSignIn}>
        <div className="mb-3 input-wrapper">
          <Icon name="email" height="20" width="20" className="icon-left" />
          <input
            className="form-control sign-in-input"
            type="email"
            id="email"
            placeholder="Email address"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </div>
        <PasswordField
          value={password}
          handleChange={e => setPassword(e.target.value)}
        />
        <Link to="/forgot_password" className="forgot">
          Forgot password?
        </Link>
        <PrimaryButton size="medium" type="submit" disabled={loading}>
          Sign In
          <Icon name="login" height="20" width="20" />
        </PrimaryButton>
      </form>
    </div>
  )
}

export default SignInRoute
