import cn from "classnames"
import styles from "./label.candidateProfile.module.scss"

const LabelCandidate = ({ label, type = "engaged" }) => {
  return (
    <span
      className={cn(styles.label, {
        [styles.label__engaged]: type === "engaged",
        [styles.label__interested]: type === "interested",
        [styles.label__looking]: type === "looking",
        [styles.label__notLooking]: type === "not_looking",
      })}
    >
      {label}
    </span>
  )
}

export default LabelCandidate
