import HeaderCardExperience from "./Header"
import DescriptionCardExperience from "./Description"
import TagsCardExperience from "./Tags"
import styles from "./cardExperience.salesExperience.module.scss"

const CardExperience = ({ current, experience }) => {
  return (
    <article className={styles.cardExperience}>
      <HeaderCardExperience current={current} experience={experience} />
      <DescriptionCardExperience description={experience?.description} />
      <TagsCardExperience experience={experience} />
    </article>
  )
}

export default CardExperience
