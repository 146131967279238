import apiCall from "utils/api"

export const searchCompanies = search => {
  const options = {
    params: {
      name: search,
    },
  }
  return apiCall("get", `companies/competitor_search`, options)
    .then(response => {
      return response.data
    })
    .catch(error => {
      console.error("Search Companies", error)
      throw error
    })
}

export const getJobs = () => {
  return apiCall("get", `jobs`)
    .then(response => {
      return response.data
    })
    .catch(error => {
      console.error("Company Jobs", error)
      throw error
    })
}

export const getCompanyById = id => {
  return apiCall("get", `companies/${id}`)
    .then(response => {
      return response.data
    })
    .catch(error => {
      console.error("getCompanyById", error)
      throw error
    })
}

export const getFormOptions = () => {
  return apiCall("get", `companies/form_options`)
    .then(response => {
      return response.data
    })
    .catch(error => {
      console.error("Company Form Options", error)
      throw error
    })
}

export const getSignUpSearch = search => {
  return apiCall("get", `companies/sign_up_search?search=${search}`)
    .then(response => {
      return response.data
    })
    .catch(error => {
      console.error("Company Jobs", error)
      throw error
    })
}

export const searchCompaniesProfile = search => {
  const options = {
    params: {
      search,
    },
  }
  return apiCall("get", `companies/profile_search`, options)
    .then(response => {
      return response.data
    })
    .catch(error => {
      console.error("Search Companies", error)
      throw error
    })
}
