//import Icon from "components/Icon"
import { YEARS_LABELS } from "constants/candidates"
import Description from "components/candidates/Components/DescriptionCard"
import TagResume from "components/candidates/Components/Tag"
import TagLink from "components/candidates/Components/TagLink"
import styles from "./header.sidebar.candidates.module.scss"

const HeaderCard = ({ candidate }) => {
  return (
    <article className={styles.headerCard}>
      {/*    <Icon
        name={candidate?.save ? "bookmark-fill" : "bookmark"}
        height="34"
        width="25"
        className={styles.headerCard__mark}
      /> */}

      <h3 className={styles.headerCard__title}>
        {" "}
        {candidate?.first_name} {candidate?.last_name}
      </h3>

      <div className={styles.headerCard__tags}>
        <TagResume
          label={`${
            YEARS_LABELS[candidate?.years_selling_experience || 0]
          } Experience`}
          icon="clock"
          widthIcon="14"
          heightIcon="14"
        />
        {candidate?.linkedin_url && (
          <TagLink
            label="Linkedin"
            icon="linkedin"
            url={candidate?.linkedin_url}
          />
        )}
        {candidate?.resume?.url && (
          <TagLink label="Resume" icon="resume" url={candidate?.resume?.url} />
        )}
      </div>

      <Description strong candidate={candidate} />
    </article>
  )
}

export default HeaderCard
