import classNames from "classnames"

import styles from "./JobsTabs.module.scss"

const JobsTabs = ({ fetchJobs, counts, activeTab }) => (
  <div className={styles.wrapper}>
    <button
      className={classNames({
        [`${styles.button}`]: true,
        [`${styles.button__active}`]: activeTab === "Active",
      })}
      onClick={() => {
        fetchJobs(1, undefined, undefined, "Active")
      }}
    >
      Active{" "}
      <div className={styles.badge}>{counts?.active_jobs_count || 0}</div>
    </button>

    <button
      className={classNames({
        [`${styles.button}`]: true,
        [`${styles.button__active}`]: activeTab === "Closed",
      })}
      onClick={() => fetchJobs(1, undefined, undefined, "Closed")}
    >
      Closed{" "}
      <div className={styles.badge}>{counts?.closed_jobs_count || 0}</div>
    </button>
    <button
      className={classNames({
        [`${styles.button}`]: true,
        [`${styles.button__active}`]: activeTab === "Rejected",
      })}
      onClick={() => fetchJobs(1, undefined, undefined, "Rejected")}
    >
      Declined{" "}
      <div className={styles.badge}>{counts?.rejected_jobs_count || 0}</div>
    </button>
  </div>
)

export default JobsTabs
