import { TYPES } from "constants/candidates"
import LabelCandidate from "components/candidates/Components/Label"

import styles from "./labels.sidebar.candidates.module.scss"

const LabelsCard = ({ candidate }) => {
  return (
    <article className={styles.labelsCard}>
      {TYPES[candidate?.search_status] && (
        <LabelCandidate
          label={candidate?.search_status}
          type={TYPES[candidate?.search_status]}
        />
      )}

      {candidate?.engaged && <LabelCandidate label="Engaged" type="engaged" />}
    </article>
  )
}

export default LabelsCard
