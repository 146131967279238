//import SecondaryLightButton from "components/shared/Buttons/SecondaryLight"
import PrimaryButton from "components/shared/Buttons/Primary"
import styles from "./footer.sidebar.candidates.module.scss"

const FooterCard = ({ id, save, candidateConnect }) => {
  return (
    <article className={styles.footerCard}>
      {/*    <SecondaryLightButton className={styles.rating__action} onClick={save}>
        Save Candidate
      </SecondaryLightButton> */}

      <PrimaryButton
        className={styles.rating__action}
        onClick={candidateConnect}
      >
        Connect With Candidate
      </PrimaryButton>
    </article>
  )
}

export default FooterCard
