export const TYPES = {
  "Actively Looking": "looking",
}

export const SALES_CYCLE = {
  id: 6,
  name: "sales_cycle_length",
  isRequired: true,
  title: "Sales Cycle Length",
  description:
    "Drag to indicate your opinion of the typical length of the sales cycle for this role, as measured from the time the opportunity is created to when it's won.",
  isString: true,
  min: 0,
  progress: 1,
  max: 16,
  minLabel: "1 Week",
  maxLabel: "12 Months",
  placeholder: "0 Weeks",
  inputReadonly: true,
  labels: [
    "1 Week",
    "2 Weeks",
    "3 Weeks",
    "4 Weeks",
    "5 Weeks",
    "6 Weeks",
    "2 Months",
    "3 Months",
    "4 Months",
    "5 Months",
    "6 Months",
    "7 Months",
    "8 Months",
    "9 Months",
    "10 Months",
    "11 Months",
    "12 Months",
  ],
}

export const YEARS_LABELS = {
  0: "< 1 Years",
  1: "1 Year",
  2: "2 Years",
  3: "3 Years",
  4: "4 Years",
  5: "5 Years",
  6: "6 Years",
  7: "7 Years",
  8: "8 Years",
  9: "9 Years",
  10: "10 Years",
  11: "10+ Years",
}

export const SALES_CYCLE_LABELS = {
  0: "1 Week",
  1: "2 Weeks",
  2: "3 Weeks",
  3: "4 Weeks",
  4: "5 Weeks",
  5: "6 Weeks",
  6: "2 Months",
  7: "3 Months",
  8: "4 Months",
  9: "5 Months",
  10: "6 Months",
  11: "7 Months",
  12: "8 Months",
  13: "9 Months",
  14: "10 Months",
  15: "11 Months",
  16: "12 Months",
}
