import { useEffect, useState, useMemo } from "react"
import { getFormOptions } from "api/jobs"
import { getMetroLocations } from "api/metro_locations"
import classNames from "classnames"
import debounce from "lodash/debounce"
import { useSelector } from "react-redux"
import { pageCall } from "utils/mixpanel"
import { Container, OverlayTrigger, Tooltip } from "react-bootstrap"
import Input from "components/shared/Input"
import Select from "components/shared/Forms/Select"
import Icon from "components/Icon"

import styles from "./StepOne.module.scss"

const StepOne = ({
  getErrors,
  setForm,
  form,
  errors,
  isEditing,
  isEditingAndIntegrated,
}) => {
  const [formOptions, setFormOptions] = useState({})
  const [metroLocations, setMetroLocations] = useState([])
  const { currentEmployer } = useSelector(state => state.auth)

  const getOptions = (optionArray = []) =>
    optionArray.map(option => ({
      name: option?.id ? option?.name : option,
      id: option?.id ? option?.id : option,
    }))

  const changeValue = (field, value) => {
    delete errors[field]
    setForm({ ...form, [field]: value })
  }

  const fetchMetroLocations = search => {
    getMetroLocations(search)
      .then(response => setMetroLocations(response?.rows))
      .catch(error => console.error(error))
  }

  const setDefaults = () => {
    getFormOptions()
      .then(res => setFormOptions(res.data))
      .catch(error => console.error(error))

    fetchMetroLocations()
  }

  const debounceHandleSearch = useMemo(
    () => debounce(fetchMetroLocations, 200),
    [fetchMetroLocations]
  )

  const searchMetroLocations = value => {
    debounceHandleSearch(value)
  }

  useEffect(() => {
    setDefaults()
  }, [currentEmployer])

  useEffect(() => {
    pageCall("Post Job Overview", "Jobs")
  }, [])

  return (
    <div className={styles.wrapper}>
      <div className={styles.card}>
        <div className="mb-3">
          {currentEmployer?.job_board?.applicant_method === "External" && (
            <>
              <div className="mb-3">
                <label htmlFor="external_url">Tracking Link</label>
                <Input
                  type="text"
                  name="external_url"
                  label="https://"
                  error={getErrors("external_url")[0]}
                  className={classNames(styles.input, {
                    [styles.input__error]: errors?.external_url,
                  })}
                  onChange={e => changeValue("external_url", e.target.value)}
                  value={form.external_url}
                  data-cy="new-job-link-input"
                />
                <em className={classNames(styles.subtext, "form-text")}>
                  Best Practice: Generate a tracking link with "RepVue" as the
                  source to effectively track your performance
                </em>
              </div>
            </>
          )}
          <div className="mb-3">
            <label htmlFor="title">Job Title</label>
            <Input
              type="text"
              name="title"
              label="Title"
              error={getErrors("title")[0]}
              className={classNames(styles.input, {
                [styles.input__error]: errors?.title,
                [styles.input__disabled]: isEditingAndIntegrated,
              })}
              onChange={e => changeValue("title", e.target.value)}
              value={form.title}
              data-cy="new-job-title-input"
              disabled={isEditingAndIntegrated}
            />
            {isEditingAndIntegrated && (
              <em className={classNames(styles.subtext, "form-text")}>
                We ingest the job title from your ATS. Please make changes there
                if you wish to update the job listing title.
              </em>
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="role">Primary Role</label>
            <Select
              name="role"
              field="role"
              error={getErrors("role")[0]}
              className={classNames({ [styles.select__error]: errors?.role })}
              value={{
                name: form.role?.name,
                id: form.role?.id,
              }}
              onChange={e => changeValue("role", e)}
              options={getOptions(formOptions?.role)}
              isDisabled={isEditing}
            />
            {isEditing && (
              <em className={classNames(styles.subtext, "form-text")}>
                Primary Role cannot be edited. If you’d like to change this,
                please close this job and create a new one.
              </em>
            )}
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="sales_division">
            Sales Division{" "}
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="sales-division-tooltip">
                  Sales Division represents the typical prospect this role will
                  sell to. Leave this field empty if none of the choices apply
                  to your role.
                </Tooltip>
              }
            >
              <span className={styles.iconWrapper}>
                <Icon
                  className={styles.info}
                  name="info"
                  height="18"
                  width="18"
                />
              </span>
            </OverlayTrigger>
          </label>
          <Select
            name="sales_division"
            field="sales_division"
            value={{
              name: form.sales_division,
              id: form.sales_division,
            }}
            options={getOptions(formOptions?.sales_division)}
            error={getErrors("sales_division")[0]}
            className={classNames({
              [styles.select__error]: errors?.sales_division,
            })}
            onChange={e => changeValue("sales_division", e.id)}
          />
          {errors?.sales_division && (
            <p className={styles.error__message}>{errors.sales_division}</p>
          )}
        </div>
        <div className="mb-3">
          <label htmlFor="years_of_experience">Work Experience</label>
          <Select
            name="years_of_experience"
            field="years_of_experience"
            error={getErrors("years_of_experience")[0]}
            options={getOptions(formOptions?.years_of_experience)}
            className={classNames({
              [styles.select__error]: errors?.years_of_experience,
            })}
            onChange={e => changeValue("years_of_experience", e.id)}
            value={{
              name: form.years_of_experience,
              id: form.years_of_experience,
            }}
          />
          {errors?.years_of_experience && (
            <p className={styles.error}>{errors.years_of_experience}</p>
          )}
        </div>
        <div className="mb-3">
          <label htmlFor="location_type">Location Type</label>
          <Select
            name="location_type"
            field="location_type"
            options={getOptions(formOptions?.location_type)}
            onChange={e => changeValue("location_type", e.id)}
            value={{ name: form.location_type, id: form.location_type }}
            className={classNames({
              [styles.select__error]: errors?.metro_locations,
            })}
            error={getErrors("location_type")[0]}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="external_url">Locations</label>
          <Select
            name="metro_location_ids"
            options={metroLocations}
            value={form.metro_location_ids}
            isMulti={true}
            error={getErrors("metro_location_ids")[0]}
            className={classNames({
              [styles.select__error]: errors?.metro_location_ids,
            })}
            onChange={e => {
              changeValue("metro_location_ids", e)
            }}
            onInputChange={searchMetroLocations}
          />
        </div>
        <Container fluid="lg" className="g-0">
          {/* <Row>
          <Col lg={6}>
            <div className="mb-1">
              <label htmlFor="title">OTE Min</label>
              <Input
                type="number"
                name="ote_min"
                errors={getErrors("ote_min")[0]}
                placeholder={form.ote_min}
                className={styles.input}
                onChange={e => changeValue("ote_min", e.target.value)}
                value={form.ote_min}
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-1">
              <label htmlFor="title">OTE Max</label>
              <Input
                type="number"
                placeholder={form.ote_max}
                name="ote_max"
                error={getErrors("ote_max")[0]}
                className={styles.input}
                onChange={e => changeValue("ote_max", e.target.value)}
                value={form.ote_max}
              />
              {errors?.ote_max && (
                <p className={styles.error__message}>
                  OTE Max must be greater than or equal to OTE Min.
                </p>
              )}
            </div>
          </Col>
          <p className={styles.subtext}>
            OTE is optional, but if not selected we will use estimated RepVue
            data.
          </p>
        </Row> */}
          {/* <Row>
          <Col>
            <div className="d-flex align-items-start">
              <input
                id="open_to_new"
                name="open_to_new"
                className={classNames(styles.check, "form-check-input")}
                type="checkbox"
                defaultChecked={form.open_to_new}
                onChange={e => changeValue("open_to_new", e.target.value)}
              />
              <label htmlFor="open_to_new" className={styles.label__check}>
                Open To New Sales Applicants?
                <p className={styles.subtext__final}>
                  New to Sales applicants are looking to transition into their
                  first sales role. Check this option if you are open to
                  applicants with this background
                </p>
              </label>
            </div>
          </Col>
        </Row> */}
        </Container>
      </div>
    </div>
  )
}

export default StepOne
