import styles from "./tag.cardExperience.module.scss"

const Tag = ({ label, value }) => {
  return (
    <div className={styles.tag}>
      <span className={styles.tag__label}>{label}</span>
      <span className={styles.tag__value}>{value}</span>
    </div>
  )
}

export default Tag
