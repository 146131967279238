import apiCall from "utils/api"

export const getJobs = params => {
  const options = { params }
  return apiCall("get", `jobs`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      throw error
    })
}

export const getJobsApplications = params => {
  const options = { params }
  return apiCall("get", `job_applications`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      throw error
    })
}

export const getJobApplications = (id, params) => {
  const options = { params }
  return apiCall("get", `jobs/${id}/job_applications`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      throw error
    })
}

export const getInfluencedHires = params => {
  const options = { params }
  return apiCall("get", `job_applications/influenced_hires`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      throw error
    })
}

export const updateJobApplication = (id, params) => {
  const options = {
    data: {
      job_application: {
        status: params,
      },
    },
  }

  return apiCall("patch", `job_applications/${id}`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      throw error
    })
}

export const getJobById = id => {
  return apiCall("get", `jobs/${id}`)
    .then(response => {
      return response
    })
    .catch(error => {
      throw error
    })
}

export const createJob = data => {
  const options = { data }
  return apiCall("post", `jobs`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      throw error
    })
}

export const updateJob = (id, data) => {
  const options = { data }
  return apiCall("put", `jobs/${id}`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      throw error
    })
}

export const getFormOptions = () => {
  return apiCall("get", `jobs/form_options`)
    .then(response => {
      return response
    })
    .catch(error => {
      throw error
    })
}

export const getActiveJobs = () => {
  return apiCall("get", `employer/jobs`)
    .then(response => {
      return response
    })
    .catch(error => {
      throw error
    })
}
